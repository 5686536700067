import { DefaultMenuList, PharmacistMenuList } from './constants';

export const returnMenuList = (role) => {
    if (role === 1) {
        return PharmacistMenuList
    } else {
        return DefaultMenuList
    }
}

export const handleMenuNavigation = (item, navigate, setPickerDrawerOpened) => {
    if (item.label === "Post") {
        setPickerDrawerOpened(true);
    } else {
        navigate(item.url);
    }
};