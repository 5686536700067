import React, { useState } from 'react';
import { BringzzButton, BringzzImage, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzImageCheckbox
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';
import PEACE_IMAGE from 'assets/images/peace.png'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

// TODO: BringzzRadioList should accept multiple selections?
const ConsumerSuccessPage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();

    const [profileImage, setProfileImage] = useState(null)

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{

            }}
            footer={
                <div className='pb-8 px-6'>
                    <div className='pb-2'>
                        <BringzzText tag='h5' className={'font-normal'}>Your registration is now complete. </BringzzText>
                        <BringzzText tag='h5' className={'font-normal'}>By clicking the button you certify that you are 14 years of age or older, and agree to the <span className='font-bold underline'>Terms of Use</span> and <span className='font-bold underline'>Privacy Policy</span>. You can adjust your information under the settings menu.</BringzzText>
                    </div>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
                            navigate('/')
                        }}
                    >
                        Login
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>
                            <div className='pl-2 flex flex-col space-y-6 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='text-center pl-2 text-midnight-blue font-bold'>
                                    Welcome Patrick
                                </BringzzText>
                                <BringzzText tag="h5" className="font-normal">It is great to have you now on the platform. Take an informed decision on your medicine, reserve and get them your self or get your products to your doorsteps</BringzzText>
                            </div>
                            <div className='mt-12 flex justify-center relative'>
                                {profileImage ? (
                                    <img src={profileImage} alt="Profile" className='rounded-full border-8 border-magic-lilac h-48 w-48' />
                                ) : (
                                    <div className='flex justify-center border-8 border-magic-lilac text-white font-bold text-4xl items-center rounded-full bg-midnight-blue h-48 w-48'>P</div>
                                )}
                                <BringzzImage className='absolute p-16 left-14' src={PEACE_IMAGE}></BringzzImage>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BringzzPageContainer >
    );
};

export default ConsumerSuccessPage;
