import React, { createContext, useEffect, useState } from 'react';
import { useSocket } from './SocketContext';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const {socket} = useSocket();
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        if (socket) {
            const handleNotification = (newNotification) => {
                setNotifications((prevNotifications) => [...prevNotifications, newNotification]);
            };

            socket.on('notifications', handleNotification);

            return () => socket.off('notification', handleNotification);
        }
    }, [socket]);
    const value = {
        notifications,
    };
    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    );
};