import React, { useEffect, useState } from 'react';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
	BringzzImage,
	BringzzPostingCard,
	BringzzButton,
	BringzzModal
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import AvatarExample from 'assets/images/AvatarExample.png';
import PostExample1 from 'assets/images/PostExample1.png';

import useUser from 'hooks/useUser';
function useQuery() {
	return new URLSearchParams(useLocation().search);
}
let state = {
	checked: <div></div>,
	unChecked: <div></div>
};
const PostDetail = () => {
	const { backPress } = useNavigation();
	let query = useQuery();
	const { sendRequest } = useRequest();
	const { getUser } = useUser();
	const { navigate } = useNavigation();
	let id = query.get('id');
	const [post, setPost] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);

	const postsArray = [
		{
			uuid: '1',
			type: 3,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '2',
			type: 3,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '3',
			type: 3,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '4',
			type: 2,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '5',
			type: 2,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '6',
			type: 2,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '7',
			type: 1,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '8',
			type: 1,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '9',
			type: 1,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		}
	]

	useEffect(() => {
		if (id) {
			postsArray.map((post) => {
				if (post.uuid == id) {
					setPost(post);
				}
			})
		}

	}, [id])
	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),

				title: <BringzzText tag='h2'>Published Post</BringzzText>
			}}
			footer={
				<div className='pb-28 px-6 flex flex-row space-x-4'>
					<BringzzButton
						onClick={() => setModalOpen(true)}
						size='lg'
						className="border w-full border-black justify-center">
						Delete
					</BringzzButton>
					<BringzzButton
						onClick={() => navigate(`/settings/posts/edit?id=${post.uuid}`)}
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'>
						Edit
					</BringzzButton>
				</div>
			}
		>
			<div className='p-4 space-y-4'>
				<BringzzPostingCard
					avatar={AvatarExample}
					name='Apotheke Mustermann'
					header={<BringzzImage className="w-full h-auto" src={PostExample1} alt="post image"></BringzzImage>}
					likesCount={1200}
					commentsCount={40}
					title='Fall time is cold time: How to prepare for the cold season'
					description="Turpis rutrum amet vitae ac integer et pharetra gravida. Praesent
					laoreet amet Dui amet felis gravida convallis nisi suspendisse sed."
					action={{
						label: 'View products',
						icon: 'IconEbook',
						iconFolder: 'LineIcons',
						onClick: () => console.log("text")
					}}
				></BringzzPostingCard>
			</div>
			{modalOpen && (
				<BringzzModal
					title={'Delete this post?'}
					description={`Sed pellentesque porttitor ipsum non libero lacinia. Fermentum aliquam nibh in.`}
					onCancel={() => setModalOpen(false)}
					onConfirm={async () => {
						await sendRequest({
							method: 'DELETE',
							url: `/settings/posts/detail`,
							data: {
								uuid: post.id,
							}
						})
							.then(response => {
								if (response.status === 'success')
									navigate(`/settings/posts`, false);
							})
							.catch(error => {
								console.log(error);
							});
					}}
					confirmButtonText='Delete'
				/>
			)}
		</BringzzPageContainer>
	);
};

export default PostDetail;
