import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzButton, BringzzIcon, BringzzText, BringzzInput
} from "@bringzz/components";
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';

const EditDoctorVerificationView = () => {
    const { backPress, navigate } = useNavigation();
    const [doctorType, setDoctorType] = useState(null)
    const [doctorId, setDoctorId] = useState(null)
    const [doctorUID, setDoctorUID] = useState(null)
	const { sendRequest, loading } = useRequest();

    const { getUser } = useUser();
    const user = getUser();

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Doctor Verification</BringzzText>,
            }}
            footer={
                <div className='pb-28 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
							await sendRequest({
								method: 'PATCH',
								url: `/account/doctor`,
								data: { doctorType,
                                    doctorId,
                                    doctorUid:doctorUID }
							})
								.then(response => {
									if (response.status === 'success')
										navigate(`/account`, false);
								})
								.catch(error => {
									console.log(error);
								});
						}}
                        disabled={loading || (!doctorType && !doctorId  && !doctorUID)}
                    >
                        Save
                    </BringzzButton>
                </div>
            }
        >
            <div className='p-6 space-y-4'>
                <BringzzInput onChange={(value)=> setDoctorType(value) } defaultValue={user.doctorType} id='doctorType' label="Doctor Type" containerClassname="w-full" placeholder="Doctor Type" />
                <BringzzInput onChange={(value)=> setDoctorId(value)} defaultValue={user.doctorId} id='doctorId' label="Doctor ID" containerClassname="w-full" placeholder="Doctor ID" />
                <BringzzInput onChange={(value)=> setDoctorUID(value)} defaultValue={user.doctorUID} id='doctorUID' label="Doctor UID" containerClassname="w-full" placeholder="Doctor UID" />
            </div>
        </BringzzPageContainer>
    );
}

export default EditDoctorVerificationView;
