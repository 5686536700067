import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzButton,
	BringzzIcon,
	BringzzText,
	BringzzInput,
	BringzzBottomDrawer,
	BringzzImage,
	BringzzRadioList,
	BringzzRadio,
	BringzzModal
} from '@bringzz/components';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';
let state = {
	checked: <div></div>,
	unChecked: <div></div>
};
const BookmarksView = () => {
	const { backPress, navigate } = useNavigation();
	const { sendRequest, loading } = useRequest();
	const { getUser } = useUser();
	const [selectedCollection, setSelectedCollection] = useState('');
	const [openDrawer, setOpenDrawer] = useState(false);
	const [addCollectionOpen, setAddCollectionOpen] = useState(false);
	const [newCollectionName, setNewCollectionName] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [bottomDrawerType, setBottomDrawerType] = useState(null);
	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h2'>Bookmarks</BringzzText>
			}}
			footer={
				<div className='pb-28 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={async () => {
							setBottomDrawerType(null);
							setAddCollectionOpen(true);
						}}
						disabled={loading}
					>
						Create collection
					</BringzzButton>
				</div>
			}
		>
			<div className='p-4 space-y-4'>
				<div className='relative flex justify-center'>
					<div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
						<div className='flex-grow flex flex-col px-4'>
							<div className='z-10 flex flex-col '>
								{getUser().collections.map((collection, index) => (
									<CollectionItem
										onClick={() =>
											navigate(
												`/settings/bookmarks/collection?id=${collection.id}`
											)
										}
										key={index}
										collection={collection}
										setOpenDrawer={setOpenDrawer}
										setSelectedCollection={setSelectedCollection}
										items={
											collection.items.products.length +
											collection.items.posts.length
										}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<BringzzBottomDrawer
				isOpen={openDrawer}
				close={() => setOpenDrawer(false)}
				title={'Manage Collection'}
			>
				<div className='px-6 py-6'>
					<BringzzRadioList
						state={state}
						className='rounded-xl w-full text-center'
					>
						<BringzzRadio
							value='Male'
							className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex'
						>
							<div
								onClick={() => {
									setBottomDrawerType('rename');
									setOpenDrawer(false);
									setAddCollectionOpen(true);
								}}
								className='w-full text-start'
							>
								Rename
							</div>
						</BringzzRadio>
						<BringzzRadio
							value='Female'
							className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400'
						>
							<div
								onClick={() => {
									setOpenDrawer(false);
									setModalOpen(true);
								}}
								className='w-full text-start text-bold'
							>
								Delete
							</div>
						</BringzzRadio>
						<BringzzRadio
							className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400'
							value='Diverse'
						>
							<div className='w-full text-start'>Share</div>
						</BringzzRadio>
					</BringzzRadioList>
				</div>
			</BringzzBottomDrawer>
			<BringzzBottomDrawer
				isOpen={addCollectionOpen}
				close={() => setAddCollectionOpen(false)}
				title={
					bottomDrawerType == 'rename' ? 'Rename Collection' : 'New collection'
				}
			>
				<div className='px-6 py-6'>
					<BringzzInput
						label='Collection name'
						placeholder='Enter collection name'
						defaultValue={selectedCollection.name}
						containerClassname='w-full'
						onChange={(e, isValid, newValue) => {
							setNewCollectionName(newValue);
						}}
					/>
				</div>
				<div className='text-center mb-12 px-4'>
					<BringzzButton
						className='bg-magic-lilac w-full flex justify-center'
						size='lg'
						onClick={async () => {
							if (bottomDrawerType == 'rename') {
								await sendRequest({
									method: 'PATCH',
									url: `/settings/bookmarks/rename`,
									data: {
										uuid: selectedCollection.id,
										collectionName: newCollectionName
									}
								})
									.then(response => {
										if (response.status === 'success')
											setAddCollectionOpen(false);
									})
									.catch(error => {
										console.log(error);
									});
							} else {
								await sendRequest({
									method: 'POST',
									url: `/settings/bookmarks/create`,
									data: { collectionName: newCollectionName }
								})
									.then(response => {
										if (response.status === 'success')
											setAddCollectionOpen(false);
									})
									.catch(error => {
										console.log(error);
									});
							}
						}}
						disabled={loading || !newCollectionName}
					>
						{bottomDrawerType == 'rename' ? 'Save' : 'Create'}
					</BringzzButton>
				</div>
			</BringzzBottomDrawer>
			{modalOpen && (
				<BringzzModal
					title={'Delete this collection?'}
					description={`${selectedCollection.name} will be permanently deleted, including all of its contents.`}
					onCancel={() => setModalOpen(false)}
					onConfirm={async () => {
						await sendRequest({
							method: 'DELETE',
							url: `/settings/bookmarks/delete`,
							data: {
								id: selectedCollection.id
							}
						})
							.then(response => {
								if (response.status === 'success') setModalOpen(false);
							})
							.catch(error => {
								console.log(error);
							});
					}}
					confirmButtonText='Delete'
				/>
			)}
		</BringzzPageContainer>
	);
};
const CollectionItem = ({
	collection,
	items,
	setOpenDrawer,
	setSelectedCollection,
	onClick
}) => (
	<div
		onClick={onClick}
		className='flex items-center space-x-4 p-4 border-1 border-t border-gray-400'
	>
		<BringzzImage className='w-16 h-16' src='/images/default-image.png' alt='Image 1' />
		<div className='flex-grow'>
			<BringzzText tag='h4' className='text-lg font-semibold'>
				{collection.name}
			</BringzzText>
			<BringzzText className='text-sm text-gray-500'>{items} items</BringzzText>
		</div>
		<BringzzIcon
			size='22'
			icon='IconMore'
            className='rotate-90'
			folder='LineIcons'
			onClick={e => {
				e.stopPropagation();
				setOpenDrawer(true);
				setSelectedCollection(collection);
			}}
		/>
	</div>
);

export default BookmarksView;
