import React, { useEffect, useState } from 'react';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
	BringzzTabs,
	BringzzTab,
	BringzzTabPanel,
	BringzzImage,
	BringzzGallery,
	BringzzBottomDrawer,
	BringzzRadioList,
	BringzzRadio,
	BringzzInput,
	BringzzButton,
	BringzzModal
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import useUser from 'hooks/useUser';
function useQuery() {
	return new URLSearchParams(useLocation().search);
}
let state = {
	checked: <div></div>,
	unChecked: <div></div>
};
const BookMarksCollection = () => {
	const { backPress } = useNavigation();
	let query = useQuery();
	let collectionId = query.get('id');
	const { sendRequest, loading } = useRequest();
	const { getUser } = useUser();
	const [openDrawer, setOpenDrawer] = useState(false);
	const [addCollectionOpen, setAddCollectionOpen] = useState(false);
	const [newCollectionName, setNewCollectionName] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [bottomDrawerType, setBottomDrawerType] = useState(null);
	const [selectedCollection, setSelectedCollection] = useState();
	useEffect(() => {
		const collection = getUser().collections.find(
			collection => collection.id == collectionId
		);
		setSelectedCollection(collection);
	}, [collectionId, getUser().collections]);
	return (
		selectedCollection && (
			<BringzzPageContainer
				header={{
					left: (
						<BringzzIcon
							size='22'
							icon='IconChevronLeft'
							folder='LineIcons'
							className='cursor-pointer'
							onClick={backPress}
						/>
					),
					right: (
						<div className='flex flex-row space-x-4'>
							<BringzzIcon
								size='22'
								icon='IconUpload'
								folder='LineIcons'
								className='cursor-pointer'
								// onClick={backPress}
							/>
							<BringzzIcon
								size='22'
                                icon='IconMore'
								folder='LineIcons'
								className='rotate-90 cursor-pointer'
								onClick={async () => {
									setOpenDrawer(true);
								}}
							/>
						</div>
					),
					title: <BringzzText tag='h2'>{selectedCollection.name}</BringzzText>
				}}
			>
				<div className='p-4 space-y-4'>
					<BringzzTabs defaultActiveTab='tab1'>
						<BringzzTab id='tab1'>
							<BringzzText tag='h4' className='font-bold'>
								Posts
							</BringzzText>
						</BringzzTab>
						<BringzzTabPanel id='tab1'>
							<BringzzGallery>
								{selectedCollection.items.posts.map((post, index) => (
									<>
										<BringzzImage className='w-full' src='/images/default-image.png' alt='Image 1' />
									</>
								))}
							</BringzzGallery>
						</BringzzTabPanel>

						<BringzzTab id='tab2'>
							<BringzzText tag='h4' className='font-bold'>
								Products
							</BringzzText>
						</BringzzTab>
						<BringzzTabPanel id='tab2'>
							<BringzzGallery>
								{selectedCollection.items.products.map((products, index) => (
									<>
										<BringzzImage className='w-full' src='/images/default-image.png' alt='Image 1' />
									</>
								))}
							</BringzzGallery>
						</BringzzTabPanel>
					</BringzzTabs>
					<BringzzBottomDrawer
						isOpen={openDrawer}
						close={() => setOpenDrawer(false)}
						title={'Manage Collection'}
					>
						<div className='px-6 py-6'>
							<BringzzRadioList
								state={state}
								className='rounded-xl w-full text-center'
							>
								<BringzzRadio
									value='Male'
									className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex'
								>
									<div
										onClick={() => {
											setBottomDrawerType('rename');
											setOpenDrawer(false);
											setAddCollectionOpen(true);
										}}
										className='w-full text-start'
									>
										Rename
									</div>
								</BringzzRadio>
								<BringzzRadio
									value='Female'
									className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400'
								>
									<div
										onClick={() => {
											setOpenDrawer(false);
											setModalOpen(true);
										}}
										className='w-full text-start text-bold'
									>
										Delete
									</div>
								</BringzzRadio>
								<BringzzRadio
									className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400'
									value='Diverse'
								>
									<div className='w-full text-start'>Share</div>
								</BringzzRadio>
							</BringzzRadioList>
						</div>
					</BringzzBottomDrawer>
					<BringzzBottomDrawer
						isOpen={addCollectionOpen}
						close={() => setAddCollectionOpen(false)}
						title={
							bottomDrawerType == 'rename'
								? 'Rename Collection'
								: 'New collection'
						}
					>
						<div className='px-6 py-6'>
							<BringzzInput
								label='Collection name'
								placeholder='Enter collection name'
								defaultValue={selectedCollection.name}
								containerClassname='w-full'
								onChange={(e, isValid, newValue) => {
									setNewCollectionName(newValue);
								}}
							/>
						</div>
						<div className='text-center mb-12 px-4'>
							<BringzzButton
								className='bg-magic-lilac w-full flex justify-center'
								size='lg'
								onClick={async () => {
									if (bottomDrawerType == 'rename') {
										await sendRequest({
											method: 'PATCH',
											url: `/settings/bookmarks/rename`,
											data: {
												uuid: selectedCollection.id,
												collectionName: newCollectionName
											}
										})
											.then(response => {
												if (response.status === 'success')
													setAddCollectionOpen(false);
											})
											.catch(error => {
												console.log(error);
											});
									} else {
										await sendRequest({
											method: 'POST',
											url: `/settings/bookmarks/create`,
											data: { collectionName: newCollectionName }
										})
											.then(response => {
												if (response.status === 'success')
													setAddCollectionOpen(false);
											})
											.catch(error => {
												console.log(error);
											});
									}
								}}
								disabled={loading || !newCollectionName}
							>
								{bottomDrawerType == 'rename' ? 'Save' : 'Create'}
							</BringzzButton>
						</div>
					</BringzzBottomDrawer>
					{modalOpen && (
						<BringzzModal
							title={'Delete this collection?'}
							description={`${selectedCollection.name} will be permanently deleted, including all of its contents.`}
							onCancel={() => setModalOpen(false)}
							onConfirm={async () => {
								await sendRequest({
									method: 'DELETE',
									url: `/settings/bookmarks/delete`,
									data: {
										id: selectedCollection.id
									}
								})
									.then(response => {
										if (response.status === 'success') setModalOpen(false);
										backPress();
									})
									.catch(error => {
										console.log(error);
									});
							}}
							confirmButtonText='Delete'
						/>
					)}
				</div>
			</BringzzPageContainer>
		)
	);
};

export default BookMarksCollection;
