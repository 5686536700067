import { isDateAllowed, getDaysInMonth } from "../utilities";
import BringzzIcon from "../../BringzzIcon";
import React, { useState } from "react";
import BringzzText from "../../BringzzText";
import BringzzRotatingSelect from "../../BringzzRotatingSelect";
import classNames from "classnames";

const longMonths = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 }
];


const getYearsArray = (startYear = 1950) => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push({ label: String(year), value: year });
  }

  return years;
};


const DayPicker = ({
  closePicker,
  description,
  selectedDate,
  onChange,
  maxDate,
  minDate,
  year,
  month,
  setMonth,
  setYear,
}) => {
  const months = Array.from({ length: 12 }, (_, i) =>
    new Date(0, i).toLocaleString("default", { month: "long" })
  );

  const weekdays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  let currentDate = new Date();
  const yearsArray = getYearsArray(currentDate.getFullYear() - 100);
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const startDayOfMonth = new Date(year, month, 1).getDay();
  const daysInPreviousMonth = new Date(year, month, 0).getDate();

  const previousMonthDays = Array.from({ length: startDayOfMonth }).map(
    (_, index) => {
      return daysInPreviousMonth - startDayOfMonth + index + 1;
    }
  );

  const [useMonthYearPicker, setUseMonthYearPicker] = useState(false)

  const totalCells = 42;
  const remainingCells = totalCells - (previousMonthDays.length + daysInMonth);
  const nextMonthDays = Array.from({ length: remainingCells }).map(
    (_, index) => index + 1
  );

  const incrementMonth = () => {
    let newMonth = month + 1;
    let newYear = year;
    if (newMonth > 11) {
      newMonth = 0;
      newYear++;
    }
    setMonth(newMonth);
    setYear(newYear);
  };

  const decrementMonth = () => {
    let newMonth = month - 1;
    let newYear = year;
    if (newMonth < 0) {
      newMonth = 11;
      newYear--;
    }
    setMonth(newMonth);
    setYear(newYear);
  };

  const handleDayClick = (day, monthOffset) => {
    let newMonth = month + monthOffset;
    let newYear = year;

    if (newMonth > 11) {
      newMonth = 0;
      newYear++;
    } else if (newMonth < 0) {
      newMonth = 11;
      newYear--;
    }

    if (isDateAllowed(newYear, newMonth, day, maxDate, minDate)) {
      onChange(new Date(newYear, newMonth, day));
    }
  };

  const toggleMonthYearPicker = () => {
    setUseMonthYearPicker(!useMonthYearPicker)
  }

  return (
    <div className="">
      <div className="mb-4 px-2 flex justify-between">
        <BringzzText tag="h4"><span className="mr-1">&bull;</span>{description || "Select a date"}</BringzzText>
        <div>
          {" "}
          <BringzzIcon
            icon="IconClose"
            folder="LineIcons"
            size="18"
            className="hover:text-magic-lilac cursor-pointer"
            onClick={() => {
              closePicker();
            }}
          ></BringzzIcon>
        </div>
      </div>
      {/* Month Navigation Controls */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-1 items-center cursor-pointer" onClick={toggleMonthYearPicker}>
          <span className="font-medium text-md text-midnight-blue">
            {months[month]} {year}
          </span>
          <BringzzIcon
            icon="IconChevronRight"
            folder="LineIcons"
            size="17"
            className={classNames(`text-magic-lilac flex items-center`, useMonthYearPicker && "rotate-90")}
          ></BringzzIcon>
        </div>
        {!useMonthYearPicker && <div className="flex gap-2 items-center">
          <BringzzIcon
            icon="IconChevronLeft"
            folder="LineIcons"
            size="17"
            className={` text-magic-lilac flex items-center ${isDateAllowed(
              year,
              month - 1,
              getDaysInMonth(month - 1, year),
              maxDate,
              minDate
            )
              ? "cursor-pointer"
              : "cursor-not-allowed opacity-50"
              }`}
            onClick={() => {
              //Change only when at least the last day of previous month is allowed
              if (
                isDateAllowed(
                  year,
                  month - 1,
                  getDaysInMonth(month - 1, year),
                  maxDate,
                  minDate
                )
              ) {
                decrementMonth();
              }
            }}
          ></BringzzIcon>

          <BringzzIcon
            icon="IconChevronLeft"
            folder="LineIcons"
            size="17"
            className={`rotate-180 text-magic-lilac flex items-center ${isDateAllowed(year, month + 1, 1, maxDate, minDate)
              ? " cursor-pointer "
              : " cursor-not-allowed opacity-50"
              }`}
            onClick={() => {
              //Change only when at least the first day of next month is allowed
              if (isDateAllowed(year, month + 1, 1, maxDate, minDate)) {
                incrementMonth();
              }
            }}
          ></BringzzIcon>
        </div>}
      </div>

      {useMonthYearPicker ?
        <div className="flex justify-center space-x-4">
          <BringzzRotatingSelect
            options={longMonths}
            onSelect={(monthObject) => setMonth(monthObject.value)}
            defaultOption={month}
          />
          <BringzzRotatingSelect
            options={yearsArray}
            onSelect={(yearObject) => setYear(yearObject.value)}
            defaultOption={year}
          />
        </div>

        :
        <div>
          {/* Weekday Headers */}
          <div className="grid grid-cols-7 mb-2">
            {weekdays.map((day) => (
              <div key={day} className="text-center font-medium text-xs text-gray-400">
                {day}
              </div>
            ))}
          </div>

          {/* Days Grid */}
          <div className="grid grid-cols-7 gap-0 mb-6">
            {/* Previous Month's Days */}
            {previousMonthDays.map((day) => (
              <button
                key={`prev-${day}`}
                className={`w-9 h-9 p-1 flex justify-center items-center bg-gray-100 text-gray-500 ${!isDateAllowed(year, month - 1, day, maxDate, minDate)
                  ? "opacity-40 cursor-not-allowed"
                  : "cursor-pointer"
                  } `}
                onClick={() => {
                  if ((isDateAllowed(year, month - 1, day), maxDate, minDate))
                    handleDayClick(day, -1);
                }}
              >
                {day}
              </button>
            ))}

            {/* Current Month's Days */}
            {Array.from({ length: daysInMonth }, (_, i) => i + 1).map((day) => (
              <button
                key={day}
                className={`w-9 h-9 p-1 flex justify-center items-center rounded-full ${selectedDate.getDate() === day &&
                  selectedDate.getMonth() === month &&
                  selectedDate.getFullYear() === year
                  ? "bg-magic-lilac text-white"
                  : ""
                  } ${!isDateAllowed(year, month, day, maxDate, minDate)
                    ? "opacity-40 cursor-not-allowed"
                    : "cursor-pointer"
                  } `}
                onClick={() => {
                  if (isDateAllowed(year, month, day, maxDate, minDate))
                    handleDayClick(day, 0);
                }}
              >
                {day}
              </button>
            ))}

            {/* Next Month's Days */}
            {nextMonthDays.map((day) => (
              <button
                key={`next-${day}`}
                className={`w-9 h-9 p-1 flex justify-center items-center  bg-gray-100 text-gray-500 ${!isDateAllowed(year, month + 1, day, maxDate, minDate)
                  ? "opacity-40 cursor-not-allowed"
                  : "cursor-pointer"
                  }`}
                onClick={() => {
                  if (isDateAllowed(year, month + 1, day, maxDate, minDate))
                    handleDayClick(day, 1);
                }}
              >
                {day}
              </button>
            ))}
          </div>

          <div className="w-full mb-4 flex justify-center">
            <button
              onClick={() => {
                closePicker();
              }}
              className="w-full bg-magic-lilac p-2 font-bold text-midnight-blue text-sm rounded-full"
            >
              Continue
            </button>
          </div>
        </div>

      }


    </div>
  );
};

export default DayPicker;
