const DefaultMenuList = [
	{
		label: 'Home',
		icon: 'IconBringzz',
		folder: 'CustomIcons',
		url: '/home'
	},
	{
		label: 'Search',
		icon: 'IconSearchAlt',
		folder: 'LineIcons',
		url: '/search'
	},
	{
		label: 'Scan',
		icon: 'IconBarcode',
		folder: 'LineIcons',
		url: '/scan'
	},
	{
		label: 'Basket',
		icon: 'IconShoppingBag14',
		folder: 'LineIcons',
		url: '/basket'
	},
	{
		label: 'Profile',
		icon: 'IconUserAlt1',
		folder: 'LineIcons',
		url: '/profile'
	}
];

const PharmacistMenuList = [
	{
		label: 'Home',
		icon: 'IconBringzz',
		folder: 'CustomIcons',
		url: '/home'
	},
	{
		label: 'Search',
		icon: 'IconSearchAlt',
		folder: 'LineIcons',
		url: '/search'
	},
	{
		label: 'Post',
		icon: 'IconCirclePlus',
		folder: 'LineIcons',
		url: '/posts'
	},
	{
		label: 'Basket',
		icon: 'IconShoppingBag14',
		folder: 'LineIcons',
		url: '/basket'
	},
	{
		label: 'Profile',
		icon: 'IconUserAlt1',
		folder: 'LineIcons',
		url: '/profile'
	}
];

module.exports = {
	DefaultMenuList, PharmacistMenuList
};
