import React, { useState } from 'react';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
	BringzzTabs,
	BringzzTab,
	BringzzTabPanel,
	BringzzImage,
	BringzzGallery,
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import useUser from 'hooks/useUser';
function useQuery() {
	return new URLSearchParams(useLocation().search);
}
let state = {
	checked: <div></div>,
	unChecked: <div></div>
};

const postsArray = [
	{
		uuid: '1',
		type: 3,
		headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
		description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
		content: 'string',
		release: new Date(),
		likesHidden: false,
		viewsHidden: false,
		commentsHidden: false,
		PostImg: '',
		PostImgAlt: '',
		validFrom: new Date(), // -> for type 1
		validTilpost: new Date(), // -> for type 1
		products: [], // -> for type 1
		link: 'string', // -> for type 2
		img_caption: 'string' // -> for type 3
	},
	{
		uuid: '2',
		type: 3,
		headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
		description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
		content: 'string',
		release: new Date(),
		likesHidden: false,
		viewsHidden: false,
		commentsHidden: false,
		PostImg: '',
		PostImgAlt: '',
		validFrom: new Date(), // -> for type 1
		validTilpost: new Date(), // -> for type 1
		products: [], // -> for type 1
		link: 'string', // -> for type 2
		img_caption: 'string' // -> for type 3
	},
	{
		uuid: '3',
		type: 3,
		headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
		description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
		content: 'string',
		release: new Date(),
		likesHidden: false,
		viewsHidden: false,
		commentsHidden: false,
		PostImg: '',
		PostImgAlt: '',
		validFrom: new Date(), // -> for type 1
		validTilpost: new Date(), // -> for type 1
		products: [], // -> for type 1
		link: 'string', // -> for type 2
		img_caption: 'string' // -> for type 3
	},
	{
		uuid: '4',
		type: 2,
		headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
		description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
		content: 'string',
		release: new Date(),
		likesHidden: false,
		viewsHidden: false,
		commentsHidden: false,
		PostImg: '',
		PostImgAlt: '',
		validFrom: new Date(), // -> for type 1
		validTilpost: new Date(), // -> for type 1
		products: [], // -> for type 1
		link: 'string', // -> for type 2
		img_caption: 'string' // -> for type 3
	},
	{
		uuid: '5',
		type: 2,
		headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
		description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
		content: 'string',
		release: new Date(),
		likesHidden: false,
		viewsHidden: false,
		commentsHidden: false,
		PostImg: '',
		PostImgAlt: '',
		validFrom: new Date(), // -> for type 1
		validTilpost: new Date(), // -> for type 1
		products: [], // -> for type 1
		link: 'string', // -> for type 2
		img_caption: 'string' // -> for type 3
	},
	{
		uuid: '6',
		type: 2,
		headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
		description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
		content: 'string',
		release: new Date(),
		likesHidden: false,
		viewsHidden: false,
		commentsHidden: false,
		PostImg: '',
		PostImgAlt: '',
		validFrom: new Date(), // -> for type 1
		validTilpost: new Date(), // -> for type 1
		products: [], // -> for type 1
		link: 'string', // -> for type 2
		img_caption: 'string' // -> for type 3
	},
	{
		uuid: '7',
		type: 1,
		headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
		description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
		content: 'string',
		release: new Date(),
		likesHidden: false,
		viewsHidden: false,
		commentsHidden: false,
		PostImg: '',
		PostImgAlt: '',
		validFrom: new Date(), // -> for type 1
		validTilpost: new Date(), // -> for type 1
		products: [], // -> for type 1
		link: 'string', // -> for type 2
		img_caption: 'string' // -> for type 3
	},
	{
		uuid: '8',
		type: 1,
		headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
		description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
		content: 'string',
		release: new Date(),
		likesHidden: false,
		viewsHidden: false,
		commentsHidden: false,
		PostImg: '',
		PostImgAlt: '',
		validFrom: new Date(), // -> for type 1
		validTilpost: new Date(), // -> for type 1
		products: [], // -> for type 1
		link: 'string', // -> for type 2
		img_caption: 'string' // -> for type 3
	},
	{
		uuid: '9',
		type: 1,
		headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
		description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
		content: 'string',
		release: new Date(),
		likesHidden: false,
		viewsHidden: false,
		commentsHidden: false,
		PostImg: '',
		PostImgAlt: '',
		validFrom: new Date(), // -> for type 1
		validTilpost: new Date(), // -> for type 1
		products: [], // -> for type 1
		link: 'string', // -> for type 2
		img_caption: 'string' // -> for type 3
	}
]

const Posts = () => {
	const { backPress } = useNavigation();
	let query = useQuery();
	const { navigate } = useNavigation();

	const { sendRequest, loading } = useRequest();

	const [openDrawer, setOpenDrawer] = useState(false);

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				right: (
					<div className='flex flex-row space-x-4'>
						<BringzzIcon
							size='22'
							icon='IconUpload'
							folder='LineIcons'
							className='cursor-pointer'
						// onClick={backPress}
						/>
						<BringzzIcon
							size='22'
							icon='IconMore'
							folder='LineIcons'
							className='rotate-90 cursor-pointer'
							onClick={async () => {
								setOpenDrawer(true);
							}}
						/>
					</div>
				),
				title: <BringzzText tag='h2'>Posts</BringzzText>
			}}
		>
			<div className='p-4 space-y-4'>
				<BringzzTabs defaultActiveTab='tab1'>
					<BringzzTab id='tab1'>
						<BringzzText tag='h4' className='font-bold'>
							Published
						</BringzzText>
					</BringzzTab>
					<BringzzTabPanel id='tab1'>
						<BringzzGallery>
							{postsArray.map(post => post.type == 2 ? post : false).filter(Boolean).map((post, index) => (
								<>
									<div onClick={() => navigate(`/settings/posts/detail?id=${post.uuid}`)} >
										<BringzzImage className='w-full' src='/images/default-image.png' alt='Image 1' />
									</div>
								</>

							))}
						</BringzzGallery>
					</BringzzTabPanel>

					<BringzzTab id='tab2'>
						<BringzzText tag='h4' className='font-bold'>
							Unpublished
						</BringzzText>
					</BringzzTab>
					<BringzzTabPanel id='tab2'>
						<BringzzGallery>
							{postsArray.map(post => post.type != 2 ? post : false).filter(Boolean).map((post, index) => (
								<>
									<div onClick={() => navigate(`/settings/posts/detail?id=${post.uuid}`)} >
										<BringzzImage className='w-full' src='/images/default-image.png' alt='Image 1' />
									</div>
								</>

							))}
						</BringzzGallery>
					</BringzzTabPanel>
				</BringzzTabs>
			</div>
		</BringzzPageContainer>
	)
};

export default Posts;
