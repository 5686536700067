import React, { Children, useState } from "react";
import BringzzText from "../BringzzText";
import BringzzIcon from "../BringzzIcon";
import BringzzList from "../BringzzList";
import BringzzBottomDrawer from "../BringzzBottomDrawer";

import classNames from "classnames";
import BringzzPostingCardAction from "./BringzzPostingCardAction"
import PropTypes from 'prop-types';

const BringzzPostingCard = ({
  className,
  avatar,
  name,
  header,
  likesCount,
  commentsCount,
  action,
  title,
  description,
  sponsored = false,
  defaultMode = "compact", // compact | expanded
  children,
  ...props
}) => {
  const [expanded, setExpanded] = useState(defaultMode === 'expanded')
  const [drawerOpened, setDrawerOpened] = useState(false)

  const toggleMode = () => {
    setExpanded(!expanded)
  }

  const bookmarks = [
    { id: 1, title: 'Meine Favoriten', enabled: true },
    { id: 2, title: 'Collection 2', enabled: true },
  ];

  return (
    <>
      <BringzzBottomDrawer isOpen={drawerOpened} title={"Post saved"} close={() => setDrawerOpened(false)}>
        <BringzzList type="bookmarks" items={bookmarks} fixed={false}></BringzzList>
      </BringzzBottomDrawer>

      <div className={classNames("border-b border-sand", className)}>
        <div className="flex justify-between items-center py-2 px-4">
          <div className="flex items-center space-x-1">
            <img src={avatar} className="h-6 w-6" alt="avatar" />
            <BringzzText
              tag="h5"
              className={"font-bold"}
            >
              {name}
            </BringzzText>
            {sponsored && <div className="flex items-center space-x-1">
              <span className="text-[5px]">&#9679;</span>
              <BringzzText
                tag="h5"
                className={'font-normal'}
              >
                Sponsored
              </BringzzText>
            </div>}
          </div>
          <div className="flex space-x-[1px] items-center cursor-pointer">
            <span className="text-[5px]">&#9679;</span>
            <span className="text-[5px]">&#9679;</span>
            <span className="text-[5px]">&#9679;</span>
          </div>
        </div>
        <div className="relative">
          {header}

          {!expanded && <div className="absolute bottom-0 w-full px-4 pb-6 xl:px-6">
            <BringzzPostingCardAction action={action}></BringzzPostingCardAction>
          </div>}
        </div>

        <div className="px-4 xl:px-6">
          <div className=" py-2 ">
            <BringzzText
              tag="h4"
              className={"font-bold"}
            >
              {title}
            </BringzzText>
            <BringzzText
              tag="h4"
              className={classNames("font-normal", children ? "cursor-pointer" : '')}
              onClick={children && toggleMode}
            >
              {description}
              {expanded && <><br></br><span className="underline">show less</span></>}
            </BringzzText>
            {expanded && <>
              {children}
              <BringzzPostingCardAction action={action}></BringzzPostingCardAction>
            </>}
          </div>
          <div className="flex h-14 items-center justify-between ">
            <div className="flex items-center space-x-4">
              <BringzzIcon
                icon="IconHeartCopy"
                folder="LineIcons"
                size="20"
                className="cursor-pointer"
              ></BringzzIcon>
              <BringzzIcon
                icon="IconChatAlt6"
                folder="LineIcons"
                size="20"
                className="cursor-pointer"
              ></BringzzIcon>
              <BringzzIcon
                icon="IconBookmarkAlt1"
                folder="LineIcons"
                size="20"
                className="cursor-pointer"
                onClick={() => setDrawerOpened(true)}
              ></BringzzIcon>
              <div className="flex items-center space-x-1">
                {likesCount && <div>
                  <BringzzText
                    className="font-normal text-xs text-black/40"
                  >
                    {`${likesCount} Likes`}
                  </BringzzText>
                  <p className="text-[5px] text-black/40">&#9679;</p></div>}
                {commentsCount && <BringzzText
                  className="font-normal text-xs text-black/40"
                >
                  {`${commentsCount} Comments`}
                </BringzzText>}
              </div>
            </div>
            <BringzzIcon
              icon="IconUploadAlt"
              folder="LineIcons"
              className="cursor-pointer"
              size="20"
            ></BringzzIcon>
          </div>
        </div>
      </div>
    </>
  );
};

BringzzPostingCard.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  header: PropTypes.node.isRequired,
  likesCount: PropTypes.number,
  commentsCount: PropTypes.number,
  sponsored: PropTypes.bool,
  action: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    iconFolder: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  defaultMode: PropTypes.oneOf(['compact', 'expanded']),
  children: PropTypes.node,
};


export default BringzzPostingCard;
