import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzInput,
    BringzzDate,
    BringzzPageContainer,
    BringzzIcon,
    BringzzRadio,
    BringzzBottomDrawer,
    BringzzRadioList,
    BringzzMultiSelect,
    BringzzCheck
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';
import { Mode } from "@bringzz/components";
import useRequest from 'hooks/useRequest';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

let state = {
    checked: (
        <BringzzIcon
            icon="IconCheck"
            library="Custom"
            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
            size="12"
        />
    ),
    unChecked: (
        <div></div>
    ),
};

const ConsumerProfilePage = () => {
    const { backPress, navigate } = useNavigation();
    const { sendRequest, data, error, loading } = useRequest();

    let query = useQuery();
    const userId = query.get('userId');

    const defaultMedicalSpecialties = ["Allergy", "Anesthesiology", "Cardiology", "Diabetes", "Deficiency", "Dermatology", "Disability", "Emergency"]
    const [medicalSpecialties, setMedicalSpecialties] = useState(defaultMedicalSpecialties)
    const [selectedMedicalSpecialties, setSelectedMedicalSpecities] = useState(null)

    const [genderDrawerOpened, setGenderDrawerOpened] = useState(false)
    const [selectedGender, setSelectedGender] = useState(null)

    const [titleDrawerOpened, setTitleDrawerOpened] = useState(false)
    const [selectedTitle, setSelectedTitle] = useState(null)

    const [birthdayDrawerOpened, setBirthdayDrawerOpened] = useState(false)
    const [selectedBirthday, setSelectedBirthday] = useState(null)

    const [doctorDrawerOpened, setDoctorDrawerOpened] = useState(false)
    const [medicalSpecialtyOpened, setMedicalSpecialtyOpened] = useState(false)

    const [identifier, setIdentifier] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [SVNumber, setSVNumber] = useState('')
    const [formValid, setFormValid] = useState(false)

    const handleMultiSelectChange = (selectedValues) =>
        setSelectedMedicalSpecities(selectedValues)

    const validateForm = () => {
        return identifier && selectedGender && selectedTitle && firstName && lastName && selectedBirthday
    }

    let method = query.get('method');

    const returnGenderLabel = (selectedIndex) => {
        if (selectedIndex == "0") {
            return "Male"
        } else if (selectedGender == "1") {
            return "Female"
        } else {
            return "Diverse"
        }
    }

    const handleRemoveSpecialty = (specialtyToRemove) => {
        setSelectedMedicalSpecities(selectedMedicalSpecialties.filter(specialty => specialty !== specialtyToRemove));
    };

    const registerCostumer = async () => {
        const data = {
            userId: userId,
            phoneNumber: identifier,
            gender: selectedGender,
            title: selectedTitle,
            firstname: firstName,
            lastname: lastName,
            svNumber: SVNumber,
            birthday: selectedBirthday,
            language: "en"
        }
        console.log("+++++");
        console.log(data);

        const result = await sendRequest({
            method: 'POST',
            url: `/module/user/registerCustomer`,
            data
        })
            .then(response => {
                if (response.status === 'success') {
                    return response
                };
            })
            .catch(error => {
                console.error(error);
                return false;
            });

        console.log("========");
        console.log(result);
        if (result) navigate(`/register/consumer/picture?userId=${userId}`);
    }

    return (
        <BringzzPageContainer
            className="bg-white"
            steps={true}
            activeStep={1}
            header={{
                left: (
                    <div className='flex items-center cursor-pointer' onClick={() => navigate('/')}>
                        <BringzzIcon
                            size='22'
                            icon='IconChevronLeft'
                            folder='LineIcons'
                        />
                        <BringzzText tag='h4'>back</BringzzText>
                    </div>
                ),
                title: "Join Us"
            }}
            footer={
                <div className='pb-8 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
                            await registerCostumer()
                        }}
                        disabled={!validateForm() || loading}
                    >
                        Continue
                    </BringzzButton>
                </div >
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>
                            <div className='pl-2 flex flex-col space-y-6 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='pl-2 text-midnight-blue font-bold'>
                                    <div className='flex flex-col'><div>Setup</div><div>your Account</div></div>
                                </BringzzText>
                            </div>
                            <div className='space-y-6 mt-12'>
                                <div className='space-y-4'>

                                    {method == "phone" ?
                                        <BringzzInput onChange={(newValue) => setIdentifier(newValue.target.value)} label="Email" placeholder="Enter Email Address" />
                                        : <BringzzInput onChange={(newValue) => setIdentifier(newValue.target.value)} label="Phone Number" placeholder="+43" />
                                    }

                                    <div className='flex gap-2'>
                                        <BringzzInput
                                            label="Gender"
                                            placeholder={selectedGender ? returnGenderLabel(selectedGender) : "- select -"}
                                            type="text"
                                            readOnly={true}
                                            className='cursor-pointer'
                                            containerClassname='w-full'
                                            icon={{
                                                right: <BringzzIcon
                                                    icon="IconChevronDown"
                                                    library="Custom"
                                                    className="h-5 w-5 p-1 text-midnight-blue rounded-full"
                                                    size="12"
                                                />
                                            }}
                                            onClick={() => setGenderDrawerOpened(true)} />
                                        <BringzzInput
                                            label="Title"
                                            placeholder={selectedTitle ? selectedTitle : "- select -"}
                                            type="text"
                                            readOnly={true}
                                            className='cursor-pointer'
                                            containerClassname='w-full'
                                            icon={{
                                                right: <BringzzIcon
                                                    icon="IconChevronDown"
                                                    library="Custom"
                                                    className="h-5 w-5 p-1 text-midnight-blue rounded-full"
                                                    size="12"
                                                />
                                            }}
                                            onClick={() => setTitleDrawerOpened(true)} />
                                    </div>

                                    <div className='flex gap-2'>
                                        <BringzzInput onChange={(newValue) => setFirstName(newValue.target.value)} label="First Name" containerClassname="w-full" placeholder="First Name" />
                                        <BringzzInput onChange={(newValue) => setLastName(newValue.target.value)} label="Last Name" containerClassname="w-full" placeholder="Last Name" />
                                    </div>

                                    <div className='flex gap-2'>
                                        <BringzzInput onChange={(newValue) => setSVNumber(newValue.target.value)} containerClassname="w-full" label="SV-Number" tooltip="Something" placeholder="1234" />
                                        <div className='space-y-[1px] w-full'>
                                            <BringzzText tag="h3">Birthday</BringzzText>
                                            <BringzzDate
                                                placeholder={selectedBirthday ? selectedBirthday : "06/05/2024"}
                                                format="dd/MM/yyyy"
                                                onChange={(newDate) => setSelectedBirthday(newDate)}
                                                mode={Mode.DAY}
                                                maxDate={new Date()}
                                            ></BringzzDate>
                                        </div>

                                    </div>

                                    <div className='flex justify-center align-center pt-6 space-x-2'>
                                        <BringzzText tag="h3" className="font-normal">{"Are you a doctor?"}</BringzzText>
                                        <div>&bull;</div>
                                        <BringzzText tag="h3" className="underline font-bold text-midnight-blue cursor-pointer" onClick={() => setDoctorDrawerOpened(true)}>{"Verify here"}</BringzzText>
                                    </div>

                                    <BringzzBottomDrawer
                                        isOpen={genderDrawerOpened}
                                        close={() => setGenderDrawerOpened(false)}
                                        title='Select Gender'
                                    >
                                        <div className='px-4 py-6'>
                                            <BringzzRadioList
                                                onChange={(newValue) => {
                                                    console.log(newValue);
                                                    setSelectedGender(newValue)
                                                }}
                                                state={state}
                                                className="rounded-xl w-full text-center"
                                            >
                                                <BringzzRadio value="0"
                                                    className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex"
                                                >
                                                    <div>
                                                        Male
                                                    </div>
                                                </BringzzRadio>
                                                <BringzzRadio value="1"
                                                    className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400">
                                                    <div>
                                                        Female
                                                    </div>
                                                </BringzzRadio>
                                                <BringzzRadio className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400" value="2">
                                                    <div>Diverse</div>
                                                </BringzzRadio>
                                            </BringzzRadioList>
                                        </div>

                                        <div className='text-center mb-12 px-4'>
                                            <BringzzButton
                                                className='bg-magic-lilac w-full flex justify-center'
                                                onClick={() => {
                                                    console.log();
                                                    setGenderDrawerOpened(false)
                                                }}
                                            >
                                                Confirm
                                            </BringzzButton>
                                        </div>
                                    </BringzzBottomDrawer>

                                    <BringzzBottomDrawer
                                        isOpen={titleDrawerOpened}
                                        close={() => setTitleDrawerOpened(false)}
                                        title='Select Title'
                                    >
                                        <div className='px-4 py-6'>
                                            <BringzzRadioList
                                                onChange={(newValue) => {
                                                    setSelectedTitle(newValue)
                                                }}
                                                state={state}
                                                className="rounded-xl w-full text-center"

                                            >
                                                <BringzzRadio value="Mag."
                                                    className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex"
                                                >
                                                    <div>
                                                        Mag.
                                                    </div>
                                                </BringzzRadio>
                                                <BringzzRadio value="Dr."
                                                    className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400">
                                                    <div>
                                                        Dr.
                                                    </div>
                                                </BringzzRadio>
                                                <BringzzRadio className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400" value="Prof.">
                                                    <div>Prof</div>
                                                </BringzzRadio>
                                                <BringzzRadio className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400" value="BA.">
                                                    <div>BA</div>
                                                </BringzzRadio>
                                            </BringzzRadioList>
                                            <div>
                                                <BringzzInput placeholder={'e.g. Mag. Dr.'} onChange={(e, newIsValid, newValue) => {
                                                    setSelectedTitle(newValue)
                                                }}></BringzzInput>
                                                <BringzzText className='mt-1 font-normal' tag='h5'>Please write your title in case non of the above abbreviations fit to your title</BringzzText>
                                            </div>
                                        </div>

                                        <div className='text-center mb-12 px-4'>
                                            <BringzzButton
                                                className='bg-magic-lilac w-full flex justify-center'
                                                onClick={() => {
                                                    setTitleDrawerOpened(false)
                                                }}
                                            >
                                                Confirm
                                            </BringzzButton>
                                        </div>
                                    </BringzzBottomDrawer>

                                    <BringzzBottomDrawer
                                        isOpen={birthdayDrawerOpened}
                                        close={() => setBirthdayDrawerOpened(false)}
                                        title='Select your Birthday'
                                    >
                                        <div className='px-4 py-6'>

                                        </div>

                                        <div className='text-center mb-12 px-4'>
                                            <BringzzButton
                                                className='bg-magic-lilac w-full flex justify-center'
                                                onClick={() => {
                                                    console.log();
                                                    setBirthdayDrawerOpened(false)
                                                }}
                                            >
                                                Confirm
                                            </BringzzButton>
                                        </div>
                                    </BringzzBottomDrawer>
                                </div>

                            </div>
                        </div>
                    </div>
                    <BringzzBottomDrawer isOpen={doctorDrawerOpened} title={"Doctor verification"} close={() => setDoctorDrawerOpened(false)}>
                        <div className='p-4 space-y-4'>
                            <BringzzInput label="Doctor ID" placeholder="Doctor ID" tooltip="something" />
                            {!selectedMedicalSpecialties ? <BringzzInput label="Medical specialty" placeholder="Medical specialty" tooltip="something"
                                readOnly={true}
                                className='cursor-pointer'
                                containerClassname='w-full'
                                icon={{
                                    right: <BringzzIcon
                                        icon="IconChevronDown"
                                        library="LineIcons"
                                        className="h-5 w-5 p-1 text-midnight-blue rounded-full"
                                        size="12"
                                    />
                                }}
                                onClick={() => setMedicalSpecialtyOpened(true)}
                            /> : <div>
                                <div className='flex mb-2'>
                                    <BringzzText tag="h4">Medical specialty</BringzzText>
                                    <div className="ml-1 relative group">
                                        <BringzzIcon
                                            icon="IconQuestionCircle"
                                            folder="LineIcons"
                                            className="h-4 w-4 cursor-pointer text-magic-lilac"
                                            size="16"
                                        ></BringzzIcon>
                                    </div>
                                </div>
                                <div className='flex flex-wrap space-x-2 space-y-1 items-center'>
                                    {selectedMedicalSpecialties.map((specialty, index) => {
                                        return <div key={index} className='bg-sand p-1 flex cursor-pointer items-center space-x-1'
                                            onClick={() => handleRemoveSpecialty(specialty)}>
                                            <BringzzText tag='h5' className="font-normal">{specialty}</BringzzText>
                                            <BringzzIcon
                                                icon="IconPlus"
                                                folder="LineIcons"
                                                className='cursor-pointer rotate-45'
                                                size="11"
                                            ></BringzzIcon>
                                        </div>
                                    })}
                                    <BringzzIcon
                                        icon="IconPlus"
                                        folder="LineIcons"
                                        className='cursor-pointer'
                                        size="11"
                                        onClick={() => setMedicalSpecialtyOpened(true)}
                                    ></BringzzIcon>
                                </div>
                            </div>}
                            <BringzzInput label="UID-Nummer (opt)" placeholder="ATU12345678" tooltip="something" />
                            <div>
                                <div className='flex'>
                                    <BringzzText tag="h4">Austrian Medical Chamber ID-Card</BringzzText>
                                    <div className="ml-1 relative group">
                                        <BringzzIcon
                                            icon="IconQuestionCircle"
                                            folder="LineIcons"
                                            className="h-4 w-4 cursor-pointer text-magic-lilac"
                                            size="16"
                                        ></BringzzIcon>
                                    </div>
                                </div>

                                <div className='mt-2 bg-slate-100 h-40 rounded-lg flex justify-center items-center'>
                                    <BringzzButton size="lg" className="bg-midnight-blue text-white" icon={{ variant: "IconEuroCard", folder: "LineIcons", size: "16" }}>Upload</BringzzButton>
                                </div>
                            </div>

                            <BringzzText tag='h5'>Lorem ipsum dolor sit amet consectetur. Nascetur egestas imperdiet amet nulla ultricies varius </BringzzText>

                            <BringzzButton className="bg-magic-lilac w-full flex justify-center">Confirm</BringzzButton>
                        </div>
                    </BringzzBottomDrawer>

                    <BringzzBottomDrawer isOpen={medicalSpecialtyOpened} title={"Select your Medical Specialty"} close={() => setMedicalSpecialtyOpened(false)}>
                        <div className='p-4 space-y-3'>
                            <BringzzText tag='h5' className="font-normal">Please find down below the most common medical specialties. You can select more than one specialty. Please select all the ones which relates to you.</BringzzText>
                            <BringzzMultiSelect
                                onChange={handleMultiSelectChange}
                                state={{ checked: state.checked, unChecked: state.unChecked }}
                                className="divide-y"
                            >
                                {medicalSpecialties.map((specialty, index) => {
                                    return <BringzzCheck key={index} value={specialty}>{specialty}</BringzzCheck>
                                })}

                            </BringzzMultiSelect>
                            <BringzzInput onEnterPress={(newValue) => setMedicalSpecialties([newValue, ...medicalSpecialties])} placeholder='Other medical speciality'></BringzzInput>
                            <BringzzText tag='h5'>Please enter your medical specialty, in case it is not listed</BringzzText>
                            <BringzzButton
                                className='bg-magic-lilac w-full flex justify-center'
                                onClick={() => {
                                    setMedicalSpecialtyOpened(false)
                                }}
                                disabled={!selectedMedicalSpecialties}
                            >
                                Confirm
                            </BringzzButton>
                        </div>
                    </BringzzBottomDrawer>
                </div>
            </div >
        </BringzzPageContainer >
    );
};

export default ConsumerProfilePage;
