import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzButton,
	BringzzIcon,
	BringzzText,
	BringzzInput
} from '@bringzz/components';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';

const EditSVNRView = () => {
	const { backPress, navigate } = useNavigation();
	const { sendRequest, loading } = useRequest();
	const { getUser } = useUser();
	const [stateInsuranceId, setStateInsuranceId] = useState('');

	const user = getUser();

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h2'>Update SV-Nr</BringzzText>
			}}
			footer={
				<div className='pb-28 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={async () => {
							await sendRequest({
								method: 'PATCH',
								url: `/account/svnr`,
								data: { stateInsuranceId }
							})
								.then(response => {
									if (response.status === 'success')
										navigate(`/account`, false);
								})
								.catch(error => {
									console.log(error);
								});
						}}
						disabled={loading || !stateInsuranceId}
					>
						Save
					</BringzzButton>
				</div>
			}
		>
			<div className='p-6 space-y-4'>
				<BringzzInput
					onChange={(e, isValid, newValue) => {
						setStateInsuranceId(newValue);
					}}
					defaultValue={user.svId}
					id='id'
					label='SV-Nr'
					containerClassname='w-full'
					placeholder='SV-Nr'
				/>
			</div>
		</BringzzPageContainer>
	);
};

export default EditSVNRView;
