import React, { useState } from 'react';
import { BringzzIcon, BringzzText, BringzzProgressBar, BringzzButton } from "@bringzz/components";
import { useProgressOverlay } from 'context/ProgressOverlayContext'; // Import the context
import { useAuth } from 'context/AuthContext';
import classNames from 'classnames';
import { useNavigation } from 'context/NavigationContext';

const CameraOverlay = () => {
    // const [isMinimized, setIsMinimized] = useState(false); // State to handle minimized/maximized state
    const { user } = useAuth()

    if (!user) return null;

    const { navigate, isActive } = useNavigation();

    const isVisible = user.userRole === 1 && !(isActive('camera') || isActive('scan'));

    if (!isVisible) return null;

    return (
        <div className={classNames(
            "fixed right-4 z-50 bottom-28",
        )}>
            <div className="bg-magic-lilac text-white p-2 rounded-full cursor-pointer shadow-lg" onClick={() => navigate('/scan')}>
                <BringzzIcon icon="IconCamera" size="22" folder="LineIcons" className="text-white" />
                {/* You can replace "IconOrder" with any appropriate icon */}
            </div>
        </div>
    );
};

export default CameraOverlay;
