import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzRadioList,
	BringzzCountryDropdown,
	BringzzInput
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';

const AllowTrackingPage = () => {
	const { backPress } = useNavigation();

	const { navigate } = useNavigation();

	return (
		<BringzzPageContainer
			className="bg-white"
			steps={true}
			activeStep={8}
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: "Join Us",
				right: <BringzzButton size='sm' className="border border-black" onClick={() => {
					navigate('/register/faceid');
				}}>Skip</BringzzButton>
			}}
			footer={
				<div className='pb-8 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={() => {
							navigate('/register/faceid')
						}}
					>
						Allow tracking
					</BringzzButton>
				</div>
			}
			authenticated={false}
		>
			<div className='relative flex justify-center bg-magic-lilac/20'>
				<div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
					{/* Content */}
					<div className='flex-grow flex flex-col py-8 px-4'>
						<div className='z-10 flex flex-col '>
							<div className='pl-2 flex flex-col space-y-6 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
								<BringzzText tag='h1' className='pl-2 text-midnight-blue font-bold'>
									WhatsApp Business Communication
								</BringzzText>
								<BringzzText tag="h5" className="font-normal">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibu-lum consequat nisl eget ultricies mollis. Praesent eu sodales libe-ro, sit amet commodo ante. Fusce ut mauris urna.</BringzzText>
							</div>
							<div className='flex mt-20 flex-col text-center items-center'>
								<BringzzIcon
									icon="IconMountain"
									library="Custom"
									className="flex items-center justify-center h-52 w-52 bg-gray-100 rounded-full"
									size="110"
								/>
							</div>
						</div>
					</div>
				</div>
			</div >
		</BringzzPageContainer >
	);
};

export default AllowTrackingPage;
