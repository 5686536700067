import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer, BringzzButton, BringzzIcon, BringzzText, BringzzInput
} from "@bringzz/components";
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';

let state = {
    checked: (
        <BringzzIcon
            icon="IconCheck"
            library="Custom"
            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
            size="12"
        />
    ),
    unChecked: (
        <div></div>
    ),
};

const EditMailView = () => {
    const { backPress, navigate } = useNavigation();
    const [isValidInput, setIsValidInput] = useState(false)
    const [inputValue, setInputValue] = useState('');

    const { sendRequest, data, error, loading } = useRequest();

    const { getUser } = useUser();
    const user = getUser();

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Update Email</BringzzText>,
            }}
            footer={
                <div className='pb-28 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
                            await sendRequest({
                                method: 'PATCH',
                                url: `/account/email`,
                                data: { inputValue }
                            }).then((response) => {
                                if (response.status === 'success')
                                    navigate(`/account/email/verify?value=${inputValue}`, false);
                            }).catch((error) => {
                                console.log(error);
                            })
                        }}
                        disabled={!isValidInput || loading}
                    >
                        Save
                    </BringzzButton>
                </div>
            }
        >
            <div className='p-6 space-y-4'>
                <BringzzText
                    tag='h4'
                    className='text-midnight-blue/50 font-normal text-center'
                >
                    {"We'll send you an email to confirm your new email address."}
                </BringzzText>
                <BringzzInput id='email' label="Email" containerClassname="w-full" placeholder="Email"
                    onChange={(e, isValid, newValue) => {
                        setIsValidInput(isValid);
                        setInputValue(newValue);
                    }}
                    pattern='^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$'
                    defaultValue={user.email}
                />
            </div>
        </BringzzPageContainer>
    );
}

export default EditMailView;
