export function checkWebAuthnSupport() {
    return window.PublicKeyCredential !== undefined;
}

export async function requestBiometricAuthentication(email, displayName) {
    if (!checkWebAuthnSupport()) {
        console.error('WebAuthn is not supported in this browser.');
        return false;
    }

    try {
        const publicKeyCredentialCreationOptions = {
            challenge: new Uint8Array(32), // Replace with a server-generated challenge
            rp: {
                name: 'Bringzz',
                id: window.location.hostname,
            },
            user: {
                id: new Uint8Array(16), // Replace with a user ID from your database
                name: email,
                displayName,
            },
            pubKeyCredParams: [
                {
                    type: 'public-key',
                    alg: -7, // ES256 algorithm
                },
            ],
            authenticatorSelection: {
                authenticatorAttachment: 'platform',
                userVerification: 'required',
            },
            timeout: 60000,
            attestation: 'direct',
        };

        const credential = await navigator.credentials.create({ publicKey: publicKeyCredentialCreationOptions });
        console.log('Biometric authentication successful:', credential);
        localStorage.setItem('biometricAuth', 'true');
        return true;
    } catch (error) {
        console.error('Biometric authentication failed:', error);
        localStorage.setItem('biometricAuth', 'false');
        return false;
    }
}

export function isBiometricAuthEnabled() {
    return localStorage.getItem('biometricAuth') === 'true';
}

export function disableBiometricAuthentication() {
    localStorage.setItem('biometricAuth', 'false');
    return false;
}
