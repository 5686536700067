import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzButton, BringzzIcon, BringzzText, BringzzInput
} from "@bringzz/components";
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';

let state = {
    checked: (
        <BringzzIcon
            icon="IconCheck"
            library="Custom"
            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
            size="12"
        />
    ),
    unChecked: (
        <div></div>
    ),
};

const EditBioView = () => {
    const { backPress, navigate } = useNavigation();
    const { sendRequest, loading } = useRequest();
    const [inputValue, setInputValue] = useState('');
    const { getUser } = useUser();
    const user = getUser();

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Bio</BringzzText>,
            }}
            footer={
                <div className='pb-28 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
                            await sendRequest({
                                method: 'PATCH',
                                url: `/account/bio`,
                                data: { bio: inputValue }
                            }).then((response) => {
                                if (response.status === 'success')
                                    navigate(`/account`, false);
                            }).catch((error) => {
                                console.log(error);
                            })
                        }}
                        disabled={!inputValue || loading}
                    >
                        Save
                    </BringzzButton>
                </div>
            }
        >
            <div className='p-6 space-y-4'>
                <BringzzInput  onChange={(e, isValid, newValue) => {
                        setInputValue(newValue);
                    }} id='bio' type='textarea' label="Bio" containerClassname="w-full" placeholder='Bio' defaultValue={user.bio} />
            </div>
        </BringzzPageContainer>
    );
}

export default EditBioView;
