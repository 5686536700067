import React, { useState, useEffect, useRef } from 'react';
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzInput } from "@bringzz/components";
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';
import { useParams } from 'react-router-dom';
import TextMessage from "components/TextMessage";
import classnames from 'classnames';
import { useSocket } from 'context/SocketContext';

const ConversationPage = () => {
    const { backPress, navigate } = useNavigation();
    const { sendMessage, onRefetchData } = useSocket();

    const [searchQuery, setSearchQuery] = useState("");

    const { getUser } = useUser();
    const user = getUser();
    const { sendRequest, data, error, loading } = useRequest();
    const [chat, setChat] = useState(null)
    const [chatContent, setChatContent] = useState([])
    const [message, setMessage] = useState()

    const { id } = useParams();

    const lastMessageRef = useRef(null);

    const scrollToLastMessage = () => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        scrollToLastMessage();
    }, [chatContent]);

    const sendText = async () => {
        if (message.trim().length !== 0) {
            await sendRequest({
                method: 'POST',
                url: `/module/chat/sendText`,
                data: {
                    "chatId": id,
                    "textContent": message,
                    "userId": user.id
                }
            })
                .then(response => {
                    if (response.status === "success") {
                        setChatContent(response.data)
                        setMessage("")

                        // Notify socket
                        sendMessage(message)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        const fetchChat = async () => {
            await sendRequest({
                method: 'POST',
                url: `/module/chat/getChat`,
                data: { chat_id: id, user_id: user.id }
            })
                .then(response => {
                    if (response.status === "success") {
                        console.log(response);
                        setChat(response.data)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            await sendRequest({
                method: 'POST',
                url: `/module/chat/getChatContent`,
                data: { chat_id: id }
            })
                .then(response => {
                    if (response.status === "success") {
                        console.log(response);
                        setChatContent(response.data)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }

        fetchChat()

        const cleanupRefetchData = onRefetchData(fetchChat);

        return () => {
            if (cleanupRefetchData) {
                cleanupRefetchData();
            }
        };
    }, [onRefetchData])

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    return (
        <BringzzPageContainer
            footerHover={true}
            headerHover={true}
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={() => navigate('/chats')}
                />,
                title: <BringzzText tag='h2' className='text-center whitespace-nowrap'>{chat?.recipient && `${chat.recipient.firstName} ${chat.recipient.lastName}`}</BringzzText>,
            }}
            footer={
                <div className='p-4 flex space-x-2'>
                    <div className='relative w-full'>
                        <div className="absolute inset-y-0 right-0 pr-4 flex items-center">
                            <BringzzIcon
                                size="20"
                                icon="IconSend"
                                folder="LineIcons"
                                className="cursor-pointer"
                                onClick={() => {
                                    sendText()
                                }}
                            />
                        </div>
                        <textarea
                            className='shadow-xl border-slate-200 border bg-gray-100 p-3 outline-none text-midnight-blue w-full rounded-md placeholder:text-gray-300 placeholder:text-sm flex items-center text-sm pr-10'
                            placeholder='Type your message...'
                            type='textarea'
                            rows={1}
                            value={message}
                            onChange={handleMessageChange}
                        ></textarea>
                        {/* <BringzzInput
                            className='shadow-xl border-slate-200 border bg-gray-100'
                            placeholder='Type your message...'
                            type='textarea'
                            onChange={(e, newIsValid, newValue) => setMessage(newValue)}
                            icon={{
                                right: <BringzzIcon
                                    size="20"
                                    icon="IconSend"
                                    folder="LineIcons"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        sendMessage()
                                    }}
                                />,
                            }}
                        /> */}
                    </div>
                </div>
            }
        >
            <div className="flex flex-col w-full py-12">
                {/* Conversation content */}
                <div
                    className="flex flex-col flex-1 p-4 scroll-smooth overflow-y-scroll overflow-x-hidden justify-end"
                >
                    <div className="">
                        {chatContent
                            .map((message, index) => {
                                const isSender = message.created_by_id == user.id;
                                const hasPrevious =
                                    index !== 0 ??
                                    chatContent[index - 1].created_by_id !== message.created_by_id
                                const messageSide = isSender ? "items-end" : "items-start";

                                const differentDate =
                                    index !== 0 &&
                                    chatContent[index].created.split(' ')[0] !==
                                    chatContent[index - 1].created.split(' ')[0]

                                const isLastMessage = index === chatContent.length - 1;

                                return (
                                    <div key={index} ref={isLastMessage ? lastMessageRef : null}>
                                        <div
                                            className={classnames(
                                                "items-center justify-center my-3 -mx-6",
                                                differentDate ? "flex" : "hidden"
                                            )}
                                        >
                                            <div className="flex-auto border-b"></div>
                                            <div className="flex-0 mx-4 text-xs text-slate-500">
                                                {message.created}
                                            </div>
                                            <div className="flex-auto border-b"></div>
                                        </div>

                                        <div
                                            className={classnames(
                                                hasPrevious ? "mt-1" : "mt-3",
                                                messageSide,
                                                "flex flex-col"
                                            )}
                                        >
                                            <TextMessage
                                                message={{
                                                    text: message.text_content,
                                                    date: message.created,
                                                }}
                                                isSender={isSender}
                                                hasPrevious={false}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </BringzzPageContainer>
    )
}

export default ConversationPage;