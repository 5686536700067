import React, { useState } from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzIcon, BringzzRadio, BringzzRadioList, BringzzBottomDrawer } from "@bringzz/components";
import Logo from '../../assets/BringzzLogo.png';
import './sidebar.css';
import classNames from 'classnames';
import { returnMenuList, handleMenuNavigation } from 'utils';
import { useAuth } from 'context/AuthContext';

const state = {
	checked: <div></div>,
	unChecked: <div></div>
};

const Sidebar = () => {
	const { navigate, isActive } = useNavigation();
	const { user } = useAuth()
	const [pickerDrawerOpened, setPickerDrawerOpened] = useState(null);

	const handlePickerClick = async choice => {
		if (choice == "external_link") {
			navigate('/posts/add/external-link')
		} else if (choice == "product_post") {
			navigate('/posts/add/product-post')
		} else if (choice == "article_post") {
			navigate('/posts/add/article')
		}
	}

	return (
		<div className='flex flex-col items-start w-64 h-screen sticky top-0 border border-r-zinc-300 p-5'>
			<div>
				<img
					src={Logo}
					alt='BringzzLogo'
					className='h-7 my-5 pl-2 cursor-pointer'
				></img>
			</div>
			<div className='space-y-2 w-full'>
				{returnMenuList(user.userRole).map((item, index) => {
					const active = isActive(item.url);

					return (
						<div
							key={index}
							className={classNames(
								'menu-item flex space-x-4 px-3 py-3 hover:bg-zinc-100 hover:font-bold w-full rounded-lg cursor-pointer',
								active ? 'font-bold bg-zinc-100' : ''
							)}
							onClick={() => handleMenuNavigation(item, navigate, setPickerDrawerOpened)}
						>
							<BringzzIcon
								icon={item.icon}
								folder={item.folder}
								size='23'
								className={classNames(
									'scale-target',
									active ? 'scale-[1.1]' : ''
								)}
							/>
							<div>{item.label}</div>
						</div>
					);
				})}
			</div>

			<BringzzBottomDrawer
				isOpen={pickerDrawerOpened}
				title={'Select post type'}
				close={() => setPickerDrawerOpened(false)}
			>
				<div className='px-4 py-6'>
					<BringzzRadioList
						state={state}
						className='rounded-xl w-full text-center'
						onChange={choice => {
							handlePickerClick(choice);
							setPickerDrawerOpened(false);
						}}
					>
						<BringzzRadio
							value='camera'
							className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex'
						>
							<div>External Link</div>
						</BringzzRadio>
						<BringzzRadio
							value='photos'
							className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400'
						>
							<div>Product Post</div>
						</BringzzRadio>
						<BringzzRadio
							className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400'
							value='file'
						>
							<div>Article Post</div>
						</BringzzRadio>
					</BringzzRadioList>
				</div>
			</BringzzBottomDrawer>
		</div>
	);
};

export default Sidebar;
