import React, { useState } from 'react';
import { BringzzIcon, BringzzText, BringzzProgressBar, BringzzButton } from "@bringzz/components";
import { useProgressOverlay } from 'context/ProgressOverlayContext'; // Import the context
import { useAuth } from 'context/AuthContext';
import classNames from 'classnames';
import { useNavigation } from 'context/NavigationContext';

const ProgressOverlay = () => {
    const { isVisible, activeStep } = useProgressOverlay();
    const { isActive } = useNavigation()
    const [isMinimized, setIsMinimized] = useState(false); // State to handle minimized/maximized state
    const { user } = useAuth()

    const steps = [
        { status: "Matching pharmacy...", subStatus: "Waiting for pharmacy to accept request" },
        { status: "Matching shopper...", subStatus: "Waiting for shopper to accept request" },
        { status: "Shopper is on the way...", subStatus: "Shopper is on their way to pick up" },
        { status: "Handover prescription...", subStatus: "Waiting for handover" },
        { status: "Order is being prepared...", subStatus: "Shopper is preparing your order" },
        { status: "Order picked up...", subStatus: "Shopper has picked up your order" },
        { status: "Shopper has arrived...", subStatus: "Shopper has delivered your order" }
    ];

    if (!isVisible || isActive('camera') || isActive('scan')) return null;

    const handleMinimize = () => {
        setIsMinimized(true);
    };

    const handleMaximize = () => {
        setIsMinimized(false);
    };

    return (
        <>
            {!isMinimized ? (
                <div className={classNames(
                    "fixed left-0 w-full p-6 z-50 flex justify-center items-center bottom-24",
                )}>
                    <div className="bg-magic-lilac w-full max-w-xl rounded-lg shadow-lg relative">
                        <BringzzIcon
                            size='16'
                            icon='IconClose'
                            folder='LineIcons'
                            className='cursor-pointer text-white absolute top-4 right-4'
                            onClick={handleMinimize} // Instead of closing, we minimize the overlay
                        />

                        <div className='p-4'>
                            <BringzzText tag="h4" className='font-normal text-white'>Order #1234</BringzzText>
                            <BringzzText tag="h3" className={'text-white'}>{steps[activeStep].status}</BringzzText>
                            <BringzzText tag="h5" className="font-light mb-3 text-white">{steps[activeStep].subStatus}</BringzzText>
                            <BringzzProgressBar state={activeStep + 1} mode="custom" segments={steps} />
                        </div>
                        <hr></hr>
                        <div className="flex justify-center py-3 items-center space-x-2">
                            <BringzzText tag='h4' className='text-white font-normal'>View details</BringzzText>
                            <BringzzIcon icon='IconChevronDown' folder='LineIcons' size='12' className='text-white' />
                        </div>
                    </div>
                </div>
            ) : (
                <div className={classNames(
                    "fixed right-4 z-50 shadow-lg rounded-full",
                    user.userRole != 1 ? "bottom-28" : "bottom-40"
                )}>
                    <div className="bg-magic-lilac text-white p-2 rounded-full cursor-pointer shadow-lg" onClick={handleMaximize}>
                        <BringzzIcon icon="IconBasket" size="22" folder="CustomIcons" className="text-white" />
                        {/* You can replace "IconOrder" with any appropriate icon */}
                    </div>
                </div>
            )}
        </>
    );
};

export default ProgressOverlay;
