import React, { useState } from 'react';
import {
	BringzzTopBar,
	BringzzPromoBanner,
	BringzzCategoryBox,
	BringzzBoxSlider,
	BringzzCallToAction,
	BringzzPromoCard,
	BringzzPostingCard,
	BringzzImage,
	BringzzImageSlider,
	BringzzText,
	BringzzIcon,
	BringzzBottomDrawer,
	BringzzBasketDrawer
} from "@bringzz/components";

import BringzzLogo from 'assets/BringzzLogo.png';
import WomanCallToAction from 'assets/images/WomanCallToAction.png';
import ManCallToAction from 'assets/images/ManCallToAction.png';
import PromoCardBackground from 'assets/images/PromoCardBackground.jpeg';
import AvatarExample from 'assets/images/AvatarExample.png';
import PostExample1 from 'assets/images/PostExample1.png';
import AddressSelect from '../../components/AddressSelect';
import ProductExample from 'assets/images/ProductExample.png';

import RecommendationBox from '../../components/RecommendationBox';

import { useNavigation } from '../../context/NavigationContext';
import { useProgressOverlay } from 'context/ProgressOverlayContext';

const HomePage = () => {
	const { navigate } = useNavigation();

	const [showingSelect, setShowingSelect] = useState(false);
	const [showingAddressSelect, setShowingAddressSelect] = useState(false);
	const [showingDrawer, setShowingDrawer] = useState(false);
	const [userAddress, setUserAddress] = useState(null);
	const [showOpenBasketBottomSheet, setOpenBasketBottomSheet] = useState(false);

	const closeAddressSelection = () => {
		setShowingDrawer(false);

		// wait for drawer animation animation to finish
		setTimeout(() => {
			setShowingAddressSelect(false);
		}, 500);
	};
	const onAddToCart = (productId, quantity) => {
		console.log('added to cart ', productId);
		setOpenBasketBottomSheet(true);
	};

	const [quantity, setQuantity] = useState(1);

	const { showOverlay, nextStep, resetSteps, hideOverlay } = useProgressOverlay();

	const startMatchingProcess = () => {
		showOverlay(0); // Start matching process with the first step
		// You can now call `nextStep()` whenever the progress should move forward.
	};

	const stopMatchingProcess = () => {
		resetSteps(0);
		hideOverlay()
		// Start matching process with the first step
		// You can now call `nextStep()` whenever the progress should move forward.
	};

	return (
		<div>
			<BringzzTopBar
				sections={[
					{ name: 'logo', alignment: 'start', value: BringzzLogo },
					{
						name: 'location',
						alignment: 'center',
						value: userAddress ? userAddress : 'Select address',
						onClick: () => setShowingAddressSelect(true)
					},
					{
						name: 'notification',
						alignment: 'end',
						value: true,
						onClick: () => navigate('/notifications')
					}
				]}
			/>
			<button onClick={startMatchingProcess}>Start Matching</button>
			<button onClick={nextStep}>Next Step</button>
			<button onClick={resetSteps}>Reset Steps</button>
			<button onClick={stopMatchingProcess}>Stop Matching</button>
			{showingAddressSelect && (
				<BringzzBottomDrawer
					isOpen={true}
					close={closeAddressSelection}
					title='Select adress to continue'
				>
					<AddressSelect
						close={closeAddressSelection}
						setAddress={setUserAddress}
					></AddressSelect>
				</BringzzBottomDrawer>
			)}
			<BringzzPromoBanner
				header='Free delivery – today only!'
				subHeader={
					<>
						Lectus est mi augue amet fames ultrices.{' '}
						<span className='underline'>Promo Terms</span>
					</>
				}
			></BringzzPromoBanner>
			<div className=''>
				<BringzzBoxSlider
					behavior={{
						xl: 'left',
						m: 'left'
					}}
					className='h-[69px] space-x-2 px-2 items-center py-3'
					firstItemDivider={{
						className: 'border border-magic-lilac'
					}}
				>
					<BringzzCategoryBox
						left={{
							icon: 'IconMegaphone2',
							folder: 'LineIcons'
						}}
						className='bg-magic-lilac'
					></BringzzCategoryBox>
					<BringzzCategoryBox
						left={{
							icon: 'IconTowel',
							folder: 'LineIcons'
						}}
						label={'Margen & Darm'}
						className='bg-sand'
					></BringzzCategoryBox>
					<BringzzCategoryBox
						left={{
							icon: 'IconTowel',
							folder: 'LineIcons'
						}}
						label={'Margen & Darm'}
						className='bg-sand'
					></BringzzCategoryBox>
					<BringzzCategoryBox
						left={{
							icon: 'IconTowel',
							folder: 'LineIcons'
						}}
						label={'Margen & Darm'}
						className='bg-sand'
					></BringzzCategoryBox>
					<BringzzCategoryBox
						left={{
							icon: 'IconTowel',
							folder: 'LineIcons'
						}}
						label={'Margen & Darm'}
						className='bg-sand'
					></BringzzCategoryBox>

					<BringzzCategoryBox
						left={{
							icon: 'IconTemparatureMeasuring',
							folder: 'LineIcons'
						}}
						label={'Grippe'}
						className='bg-sand'
					></BringzzCategoryBox>
					<BringzzCategoryBox
						left={{
							icon: 'IconNose',
							folder: 'LineIcons'
						}}
						label={'Allergie'}
						className='bg-sand'
					></BringzzCategoryBox>
					<BringzzCategoryBox
						label={'Show All'}
						className='bg-lemon-yellow'
					></BringzzCategoryBox>
				</BringzzBoxSlider>
				<BringzzCallToAction
					text='Scan your prescription and get your delivery!'
					primaryButton={{
						label: 'Scan now',
						className: 'bg-lemon-yellow text-olive-green'
					}}
					image={WomanCallToAction}
				></BringzzCallToAction>
				<RecommendationBox addToCart={onAddToCart} />
				<BringzzCallToAction
					text='Need advice from a pharmacist near you?'
					primaryButton={{
						label: 'Chat',
						className: 'bg-lemon-yellow text-olive-green'
					}}
					secondaryButton={{
						label: 'Call',
						className: 'bg-lemon-yellow text-olive-green'
					}}
					image={ManCallToAction}
				></BringzzCallToAction>
				<BringzzPostingCard
					avatar={AvatarExample}
					name='Apotheke Mustermann'
					header={
						<BringzzImage
							className='object-cover w-full h-auto'
							src={PostExample1}
							alt='post image'
						></BringzzImage>
					}
					likesCount={1200}
					title='Fall time is cold time: How to prepare for the cold season'
					description='Turpis rutrum amet vitae ac integer et pharetra gravida. Praesent
					laoreet amet Dui amet felis gravida convallis nisi suspendisse sed.'
					commentsCount={40}
					action={{
						label: 'Read more',
						icon: 'IconEbook',
						iconFolder: 'LineIcons',
						onClick: () => console.log('text')
					}}
				></BringzzPostingCard>
				<BringzzPostingCard
					avatar={AvatarExample}
					name='Apotheke Mustermann'
					title='Fall time is cold time: How to prepare for the cold season'
					description='Turpis rutrum amet vitae ac integer et pharetra gravida. Praesent
					laoreet amet Dui amet felis gravida convallis nisi suspendisse sed.'
					header={
						<BringzzImageSlider
							images={[
								{
									url: '/images/1.jpg'
								},
								{
									url: '/images/2.jpg'
								},
								{
									url: '/images/3.jpg'
								}
							]}
							autoSlide='true'
						></BringzzImageSlider>
					}
					likesCount={1200}
					commentsCount={40}
					action={{
						label: 'Read more',
						icon: 'IconEbook',
						iconFolder: 'LineIcons',
						onClick: () => console.log('test')
					}}
				></BringzzPostingCard>
				<BringzzPostingCard
					avatar={AvatarExample}
					name='Apotheke Mustermann'
					title='Fall time is cold time: How to prepare for the cold season'
					description='Turpis rutrum amet vitae ac integer et pharetra gravida. Praesent
					laoreet amet Dui amet felis gravida convallis nisi suspendisse sed.
					Neque ...'
					header={
						<BringzzImageSlider
							images={[
								{
									url: '/images/1.jpg'
								},
								{
									url: '/images/2.jpg'
								},
								{
									url: '/images/3.jpg'
								}
							]}
							autoSlide='true'
						></BringzzImageSlider>
					}
					likesCount={1200}
					commentsCount={40}
					action={{
						label: 'Read more',
						icon: 'IconEbook',
						iconFolder: 'LineIcons',
						onClick: () => console.log('test')
					}}
				>
					<div className='px-4'>
						<div className='py-4 flex item-center justify-center gap-6 border-b '>
							<div className='flex items-center'>
								<BringzzImage
									src='/product.png'
									className='w-[75px]'
								></BringzzImage>
							</div>
							<div className='flex-grow'>
								<div className='text-xs'> Manufacturer</div>
								<div className=''>Product Name</div>
								<div className='text-xs'>84 Stk | Kapseln</div>
								<div className='flex justify-between mt-1'>
									<div className='flex items-center justify-start gap-4 '>
										<BringzzText type={{ tag: 'span', classes: 'text-xs' }}>
											Menge:{' '}
										</BringzzText>
										<button
											className='px-2 py-1 rounded-md text-xs border border-midnight-blue  flex items-center gap-x-2'
											onClick={e => {
												e.preventDefault();
												setShowingSelect(true);
											}}
										>
											<span>{quantity}</span>{' '}
											<BringzzIcon
												icon='IconChevronDown'
												folder='LineIcons'
												size='12'
												className='p-0'
											></BringzzIcon>
										</button>
									</div>
									<div>
										<BringzzText type={{ tag: 'span' }}>
											€ {(quantity * 9).toFixed(2)}
										</BringzzText>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='px-4'>
						<div className='py-4 flex item-center justify-center gap-6 border-b '>
							<div className='flex items-center'>
								<BringzzImage
									src='/product.png'
									className='w-[75px]'
								></BringzzImage>
							</div>
							<div className='flex-grow'>
								<div className='text-xs'> Manufacturer</div>
								<div className=''>Product Name</div>
								<div className='text-xs'>84 Stk | Kapseln</div>
								<div className='flex justify-between mt-1'>
									<div className='flex items-center justify-start gap-4 '>
										<BringzzText type={{ tag: 'span', classes: 'text-xs' }}>
											Menge:{' '}
										</BringzzText>
										<button
											className='px-2 py-1 rounded-md text-xs border border-midnight-blue  flex items-center gap-x-2'
											onClick={e => {
												e.preventDefault();
												setShowingSelect(true);
											}}
										>
											<span>{quantity}</span>{' '}
											<BringzzIcon
												icon='IconChevronDown'
												folder='LineIcons'
												size='12'
												className='p-0'
											></BringzzIcon>
										</button>
									</div>
									<div>
										<BringzzText type={{ tag: 'span' }}>
											€ {(quantity * 9).toFixed(2)}
										</BringzzText>
									</div>
								</div>
							</div>
						</div>
					</div>
				</BringzzPostingCard>
				<BringzzPromoCard
					title='Ad/Promo Card headline lorem ipsum dolor sit amet est'
					subTitle='Ultrices nunc dolor purus libero metus tincidunt iaculis. Neque
            ultrices.'
					backgroundImage={PromoCardBackground}
				></BringzzPromoCard>
			</div>
			<div className='h-60'>x</div>
			<BringzzBasketDrawer
				isOpen={showOpenBasketBottomSheet}
				continueShoppingBtn={() => setOpenBasketBottomSheet(false)}
				goToBasketBtn={() => navigate('/basket')}
				basketContainerProps={{ className: 'min-h-32' }}
				products={[
					{
						imgUrl: '/product.png'
						, price: 1.2, quantity: 1
					}
				]}
			/>
		</div>
	);
};

export default HomePage;
