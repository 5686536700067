import React from 'react';
import Navbar from '../components/common/Navbar';
import { Outlet } from 'react-router-dom';

const MobileLayout = () => {
	return (
		<div className='h-screen'>
			<main className='pb-24 min-h-screen h-full'>
				<Outlet />
			</main>
			<Navbar />
		</div>
	);
};

export default MobileLayout;
