import React, { useEffect, useState } from 'react';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzText,
    BringzzInput,
    BringzzButton, BringzzBottomDrawer,
    BringzzRadioList,
    BringzzRadio,
    BringzzImage
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import {
    useDebounce
} from "@bringzz/hooks";
import classNames from 'classnames';
import usePageState from 'hooks/usePageState';

let state = {
    checked: <div></div>,
    unChecked: <div></div>
};

let products = [
    {
        id: 1,
        name: 'Product Name 1',
        manufacturer: 'Manufacturer',
        type: 'Pills',
        quantity: '64 St',
        dosage: '200mg',
        imgUrl: '/product.png',
        price: 9
    },
    {
        id: 2,
        name: 'Product Name 2',
        manufacturer: 'Manufacturer',
        type: 'Pills',
        quantity: '12 St',
        dosage: '200mg',
        imgUrl: '/product.png',
        price: 8
    },
    {
        id: 3,
        name: 'Product Name 3',
        manufacturer: 'Manufacturer',
        type: 'Pills',
        quantity: '12 St',
        dosage: '200mg',
        imgUrl: '/product.png',
        price: 8
    },
    {
        id: 4,
        name: 'Product Name 4',
        manufacturer: 'Manufacturer',
        type: 'Pills',
        quantity: '12 St',
        dosage: '200mg',
        imgUrl: '/product.png',
        price: 8
    }
];

const AddExternalLinkPage = () => {
    const { backPress } = useNavigation();
    const [addPhotoDrawer, setAddPhotoDrawer] = useState(false);
    const { navigate } = useNavigation();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProducts, setFilteredProducts] = useState(products);
    const [productDrawer, setAddProductDrawer] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]); // State for multiple selected products
    const [viewingProduct, setViewingProduct] = useState(null); // State for the product currently being viewed
    const [validFrom, setValidFrom] = useState(null)
    const [ogImage, setOgImage] = useState(null);
    const [externalLink, setExternalLink] = useState('')
    const [validExternalLink, setValidExternalLink] = useState(false)
    const [fetchingImage, setFetchingImage] = useState(false)
    const [headline, setHeadline] = useState(null)
    const [description, setDescription] = useState(null)

    const debouncedInputValue = useDebounce(externalLink, 500);

    const populateForm = (formData) => {
        setValidExternalLink(true)
        setHeadline(formData.headline || '')
        setOgImage(formData.ogImage || '')
        setDescription(formData.description || '')
        setExternalLink(formData.externalLink || '')
    }

    const formData = usePageState();

    useEffect(() => {
        if (formData) {
            populateForm(formData)
        }
    }, [formData]);

    const fetchOpenGraphImage = async (url) => {
        try {
            setFetchingImage(true);
            const response = await fetch(url);
            const html = await response.text();

            // Improved regex to handle various cases, including single quotes and optional attributes.
            const ogImageMatch = html.match(/<meta[^>]*property=["']?og:image["']?[^>]*content=["']([^"']+)["'][^>]*>/i) ||
                html.match(/<meta[^>]*content=["']([^"']+)["'][^>]*property=["']?og:image["']?[^>]*>/i);

            let imageUrl = null;

            if (ogImageMatch && ogImageMatch[1]) {
                imageUrl = ogImageMatch[1];
            } else {
                // Attempt to use a fallback by parsing the entire document for images
                const fallbackImageMatch = html.match(/<img[^>]*src=["']([^"']+)["'][^>]*>/i);
                if (fallbackImageMatch && fallbackImageMatch[1]) {
                    imageUrl = fallbackImageMatch[1];
                }
            }

            if (imageUrl) {
                // Verify if the image URL is valid and can be loaded
                const validImage = await validateImage(imageUrl);
                if (validImage) {
                    setOgImage(imageUrl);
                } else {
                    setOgImage(null); // Image couldn't be loaded, fallback to null
                }
            } else {
                setOgImage(null); // No Open Graph or fallback image found
            }
            console.log("Fetch finished");
            setFetchingImage(false);
        } catch (error) {
            console.error("Error fetching Open Graph data:", error);
            setOgImage(null);
            setFetchingImage(false);
        }
    };

    const validateImage = (url) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(true);  // Image successfully loaded
            img.onerror = () => resolve(false);  // Error loading image
        });
    };

    const handlePickerClick = async choice => {
        try {
            let base64Image = '';

            if (choice === 'camera') {
                // Handling camera input
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: true
                });
                const video = document.createElement('video');
                video.srcObject = stream;
                video.play();

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                video.onloadedmetadata = () => {
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    context.drawImage(video, 0, 0, canvas.width, canvas.height);
                    base64Image = canvas.toDataURL('image/jpeg');
                    // Stop the video stream
                    stream.getTracks().forEach(track => track.stop());
                    setOgImage(base64Image);
                    console.log(base64Image);
                };
            } else if (choice === 'photos') {
                // Handling gallery input
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*';
                input.onchange = async event => {
                    const file = event.target.files[0];
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        base64Image = reader.result;
                        setOgImage(base64Image);
                        console.log(base64Image);
                    };
                    reader.readAsDataURL(file);
                };
                input.click();
            }
        } catch (error) {
            console.error('Error handling picker choice:', error);
        }
    };

    const handleRemovePhoto = () => {
        setOgImage(null); // Only reset the photo state
    };

    const handleProductSelect = product => {
        setViewingProduct(product); // Set the product to be viewed in the drawer
    };

    const handleCancelSelection = () => {
        setViewingProduct(null); // Reset the viewing product
        setAddProductDrawer(false);
    };

    const handleApplyProduct = () => {
        if (viewingProduct) {
            setSelectedProducts([...selectedProducts, viewingProduct]); // Add the viewing product to the selected products
        }
        setViewingProduct(null); // Reset the viewing product
        setAddProductDrawer(false);
    };

    useEffect(() => {
        if (debouncedInputValue.trim() !== '') {
            const fetchData = async () => {
                await fetchOpenGraphImage(debouncedInputValue)
            };
            fetchData();
        }
    }, [debouncedInputValue]);

    const handleLinkChange = async (e, valid, value) => {
        setValidExternalLink(valid)
        if (valid)
            setExternalLink(value)
    };

    const onSubmit = () => {
        navigate(`/posts/preview/external-link`, {
            state: {
                ogImage,
                headline,
                description,
                externalLink
            }
        })
    }

    return (
        (
            <BringzzPageContainer
                header={{
                    left: (
                        <BringzzIcon
                            size="22"
                            icon="IconChevronLeft"
                            folder="LineIcons"
                            className="cursor-pointer"
                            onClick={backPress}
                        />
                    ),
                    title: <BringzzText tag="h2">External Link</BringzzText>
                }}
                footer={
                    <div className="pb-28 px-6 flex flex-row space-x-4">
                        <BringzzButton
                            disabled={true}
                            size="md"
                            className="border w-full border-black justify-center"
                        >
                            Save
                        </BringzzButton>
                        <BringzzButton
                            onClick={onSubmit}
                            size="md"
                            disabled={!ogImage || !validExternalLink || !headline || !description}
                            className="bg-magic-lilac w-full flex justify-center text-midnight-blue"
                        >
                            Share
                        </BringzzButton>
                    </div>
                }
            >
                <div className="p-4">
                    <div className="space-y-4">
                        <BringzzInput
                            label="External Link"
                            tooltip="something"
                            placeholder='http://www.yourlink.com'
                            pattern='^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/[^\s]*)?$'
                            onChange={handleLinkChange}
                            defaultValue={externalLink}
                            icon={{
                                right: <div style={{ animation: 'spin 4s linear infinite' }} className='text-black'>
                                    {fetchingImage && <svg width="22" height="22" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 2V6M12 18V22M6 12H2M22 12H18M19.0784 19.0784L16.25 16.25M19.0784 4.99994L16.25 7.82837M4.92157 19.0784L7.75 16.25M4.92157 4.99994L7.75 7.82837" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>}
                                </div>
                            }}
                        />

                        {validExternalLink && <div
                            className={
                                classNames(
                                    `w-full my-4 rounded-md flex flex-col items-center justify-center bg-white transition-all duration-300 space-y-4`,)
                            }
                        >
                            {
                                ogImage && <div className="relative w-full h-full border rounded-md">
                                    <img src={ogImage} alt={"Couldn't load image"} className="h-48 w-full object-cover rounded-md" />
                                    <BringzzIcon
                                        size="24"
                                        icon="IconTrashCan"
                                        folder="LineIcons"
                                        className="absolute text-white top-2 right-2 cursor-pointer"
                                        onClick={handleRemovePhoto}
                                    />
                                </div>
                            }
                            <div
                                className="flex cursor-pointer border rounded-md items-center justify-between w-full p-3"
                                onClick={() => setAddPhotoDrawer(true)}
                            >
                                <div className='flex items-center space-x-2'>
                                    <BringzzIcon
                                        size="24"
                                        icon="IconImage"
                                        folder="LineIcons"
                                        className="cursor-pointer text-gray-500"
                                    />
                                    <BringzzText tag="h4" className="text-gray-500">Custom Image</BringzzText>
                                </div>
                            </div>

                        </div>}
                        <BringzzInput
                            label="Headline (max. XXX char)"
                            tooltip="something"
                            placeholder='Your posting headline'
                            defaultValue={headline}
                            required={true}
                            onChange={(e, valid, value) => {
                                if (valid)
                                    setHeadline(value)
                            }}
                        />
                        <BringzzInput
                            label="Description (max. XXX char)"
                            type="textarea"
                            placeholder="Your posting description"
                            tooltip="something"
                            defaultValue={description}
                            defaultRows={5}
                            required={true}
                            onChange={(e, valid, value) => {
                                if (valid)
                                    setDescription(value)
                            }}
                        />
                    </div>
                </div>
                <BringzzBottomDrawer
                    isOpen={addPhotoDrawer}
                    title={'Upload product photo'}
                    close={() => setAddPhotoDrawer(false)}
                >
                    <div className="px-4 py-6">
                        <BringzzRadioList
                            state={state}
                            className="rounded-xl w-full text-center"
                            onChange={choice => {
                                handlePickerClick(choice);
                                setAddPhotoDrawer(false);
                            }}
                        >
                            <BringzzRadio
                                value="camera"
                                className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex"
                            >
                                <div>Camera</div>
                            </BringzzRadio>
                            <BringzzRadio
                                value="photos"
                                className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400"
                            >
                                <div>Photos</div>
                            </BringzzRadio>
                        </BringzzRadioList>
                    </div>
                </BringzzBottomDrawer>
                <BringzzBottomDrawer
                    isOpen={productDrawer}
                    title={viewingProduct ? 'Product Details' : 'Find Product'}
                    close={() => setAddProductDrawer(false)}
                >
                    <div className="px-4 py-6">
                        {viewingProduct ? (
                            <div className="space-y-4">
                                <div className="flex items-center border border-gray-300 rounded-md p-3 space-x-4">
                                    <div className="w-16 h-16 bg-gray-100 rounded-md">
                                        <BringzzImage src={viewingProduct.imgUrl} className="w-full h-full object-cover" />
                                    </div>
                                    <div>
                                        <BringzzText tag="h3" className="font-semibold">{viewingProduct.name}</BringzzText>
                                        <BringzzText tag="h5" className="text-gray-500">{viewingProduct.manufacturer}</BringzzText>
                                    </div>
                                </div>
                                <div className="flex justify-center py-2 items-center space-x-4">
                                    <div className="flex w-full border border-gray-300 p-3 space-y-1 rounded-md flex-col items-center">
                                        <div className="w-12 h-12 bg-gray-100 rounded-full"></div>
                                        <BringzzText tag="h4">{viewingProduct.type}</BringzzText>
                                        <BringzzText tag="h5" className="text-blue-500">Change</BringzzText>
                                    </div>
                                    <div className="flex w-full border border-gray-300 p-3 space-y-1 rounded-md flex-col items-center">
                                        <div className="w-12 h-12 bg-gray-100 rounded-full"></div>
                                        <BringzzText tag="h4">{viewingProduct.dosage}</BringzzText>
                                        <BringzzText tag="h5" className="text-blue-500">Change</BringzzText>
                                    </div>
                                    <div className="flex w-full border border-gray-300 p-3 space-y-1 rounded-md flex-col items-center">
                                        <div className="w-12 h-12 bg-gray-100 rounded-full"></div>
                                        <BringzzText tag="h4">{viewingProduct.quantity}</BringzzText>
                                        <BringzzText tag="h5" className="text-blue-500">Change</BringzzText>
                                    </div>
                                </div>
                                <div className="flex justify-around space-x-4">
                                    <BringzzButton
                                        size="md"
                                        className="border w-full border-black justify-center"
                                        onClick={handleCancelSelection}
                                    >
                                        Cancel
                                    </BringzzButton>
                                    <BringzzButton
                                        size="md"
                                        className="bg-magic-lilac w-full flex justify-center text-midnight-blue"
                                        onClick={handleApplyProduct}
                                    >
                                        Apply
                                    </BringzzButton>
                                </div>
                            </div>
                        ) : (
                            <>
                                <BringzzInput
                                    icon={{
                                        left: (
                                            <BringzzIcon
                                                icon="IconSearchAlt"
                                                folder="LineIcons"
                                                className="h-6 w-6"
                                                size="24"
                                            />
                                        )
                                    }}
                                    placeholder="Search here..."
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                {filteredProducts &&
                                    filteredProducts.length > 0 &&
                                    filteredProducts.map((product, index) => (
                                        <div
                                            key={product.id || index}
                                            className="py-4 px-6 flex items-center w-full border-b border-gray-200 cursor-pointer"
                                            onClick={() => handleProductSelect(product)}
                                        >
                                            <div className="flex items-center justify-center w-12 h-12 bg-gray-100 rounded-md mr-4">
                                                <BringzzImage src={product.imgUrl} className="w-full h-full object-cover" />
                                            </div>
                                            <div className="flex flex-col">
                                                <BringzzText tag="h4" className="text-base font-semibold text-gray-800">
                                                    {product.name || 'Product Name'}
                                                </BringzzText>
                                                <BringzzText tag="h5" className="text-sm text-gray-500">
                                                    {product.manufacturer || 'Manufacturer'} / {product.amount}
                                                </BringzzText>
                                            </div>
                                        </div>
                                    ))}
                            </>
                        )}
                    </div>
                </BringzzBottomDrawer>
            </BringzzPageContainer>
        )
    );
};

export default AddExternalLinkPage;
