import React, { useState, useEffect } from "react";
import BringzzText from "../BringzzText";
import BringzzButton from "../BringzzButton";

const BringzzBottomDrawer = ({
  isOpen,
  className,
  children,
  title,
  close,
  header = true,
  ...props
}) => {
  const [visibilityStyle, setVisibilityStyle] = useState({
    visibility: "hidden",
    opacity: 0,
  });
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isInitial, setIsInitial] = useState(true); // New state to track if it's in the initial state
  const [startY, setStartY] = useState(0); // State to track the start of a touch drag

  useEffect(() => {
    if (isOpen) {
      setVisibilityStyle({ visibility: "visible", opacity: 0 });
      setIsInitial(true); // Reset to initial when opened
      setIsFullscreen(false); // Ensure not fullscreen when reopened
      requestAnimationFrame(() => {
        setVisibilityStyle({ visibility: "visible", opacity: 1 });
      });
    } else {
      setVisibilityStyle({ ...visibilityStyle, opacity: 0 });
      setTimeout(() => {
        setVisibilityStyle({ visibility: "hidden", opacity: 0 });
      }, 300);
    }
  }, [isOpen]);

  const handleDragStart = (event) => {
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;
    setStartY(clientY);
  };

  const handleDragEnd = (event) => {
    const endY = event.changedTouches ? event.changedTouches[0].clientY : event.clientY;
    const deltaY = startY - endY;
    if (deltaY < -50) { // Detect swipe down
      if (isFullscreen) {
        setIsFullscreen(false); // If fullscreen, return to initial state
        setIsInitial(true);
      } else if (isInitial) {
        close(); // If initial and swiped down again, close the drawer
      }
    } else if (deltaY > 50) { // Detect swipe up
      setIsFullscreen(true);
      setIsInitial(false);
    }
  };
  const handleOverlayDismiss = (event) => {
    if (event.target === event.currentTarget) {
      close();
    }
  }

  return (
    <div
      style={visibilityStyle}
      onClick={handleOverlayDismiss}
      className={`fixed inset-0 w-full h-full bg-black/20 overflow-hidden z-40 overscroll-none transition-opacity ease-in-out duration-300 flex items-end lg:items-center justify-center ${className}`}
    >
      <div
        className={`relative ${isFullscreen ? "h-screen w-screen" : "max-h-[500px] w-full overflow-y-auto lg:max-h-[700px] lg:max-w-xl"} bg-white lg:p-6 lg:rounded-md transition-all ease-in-out duration-300 ${isOpen ? "translate-y-0" : "translate-y-full"
          }`}
        onTouchStart={handleDragStart}
        onTouchEnd={handleDragEnd}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
      >
        <div className="flex justify-center pt-2">
          <div className="bg-[#8B899B] h-2 w-16 rounded-lg"></div>
        </div>
        {header && (
            <div className="flex justify-between items-center px-4 pt-2">
            <BringzzText tag="h4"><span className="mr-1">&bull;</span>{title}</BringzzText>
            <BringzzButton
              size="sm"
              icon={{ variant: "IconClose", folder: "LineIcons", size: "14" }}
              onClick={close}
              className="!px-1"
            ></BringzzButton>
          </div>
        )}
      
        {children}
      </div>
    </div>
  );
};

export default BringzzBottomDrawer;