import React, { useState } from 'react';
import {
	BringzzAccordion,
	BringzzButton,
	BringzzPromoBanner,
	BringzzText,
	BringzzTopBar, BringzzBottomDrawer,
	BringzzRotatingSelect,
	BringzzBasketProduct
} from "@bringzz/components";
import CheckoutAddressSelect from '../../components/CheckoutAddressSelect';
import CheckoutSummary from 'components/CheckoutSummary';
import AddLocation from 'components/AddLocation';
import { useNavigation } from '../../context/NavigationContext';
import useRequest from 'hooks/useRequest';
import BringzzCheckoutPicker from 'components/BringzzCheckoutPicker';
import BasketRecommendationSection from './BasketRecommendationSection';

const BasketPage = () => {
	const { navigate } = useNavigation();
	const { sendRequest, loading } = useRequest();
	const [customAddress, setCustomAddress] = useState(null);

	const [products, setProducts] = useState([
		{
			name: 'Product Name 1',
			manufacturer: 'Manufacturer',
			amount: '84 Stk | Kapseln',
			quantity: '10',
			imgUrl: '/product.png',
			price: 9
		},
		{
			name: 'Product Name 2',
			manufacturer: 'Manufacturer 2',
			amount: '12 Stk | Kapseln',
			quantity: '3',
			imgUrl: '/product.png',
			price: 8
		}
	]);

	const center = { lat: 48.192, lng: 16.3671 };
	const zoom = 12;

	const closeMapAddressSelect = location => {
		if (location) {
			setCustomAddress(location);
		}
		setSelectingLocation(false);
	};

	const [showingDrawer, setShowingDrawer] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [showingSelect, setShowingSelect] = useState(false);
	const [showingAddressSelect, setShowingAddressSelect] = useState(false);
	const [showingCheckoutModal, setShowingCheckoutModal] = useState(false);
	const [selectingLocation, setSelectingLocation] = useState(false);
	const [quantity, setQuantity] = useState(1);
	const total = products.reduce((acc, current) => {
		return acc + current.quantity * current.price;
	}, 0);

	const options = [
		{
			value: 1,
			label: 'Stk 1'
		},
		{
			value: 2,
			label: 'Stk 2'
		},
		{
			value: 3,
			label: 'Stk 3'
		},
		{
			value: 4,
			label: 'Stk 4'
		},
		{
			value: 5,
			label: 'Stk 5'
		},
		{
			value: 6,
			label: 'Stk 6'
		}
	];

	const handleChange = e => {
		const productsCopy = [...products];
		productsCopy[selectedIndex].quantity = e.value;
		setProducts(productsCopy);
		// onCloseSelect();
	};

	const openAddressSelection = () => {
		setShowingDrawer(true);
		setShowingAddressSelect(true);
	};

	const closeAddressSelection = () => {
		setShowingDrawer(false);

		// wait for drawer animation animation to finish
		setTimeout(() => {
			setShowingAddressSelect(false);
		}, 500);
	};

	const openCheckoutModal = () => {
		setShowingDrawer(true);
		setShowingCheckoutModal(true);
	};

	const closeCheckoutModal = async () => {
		setShowingDrawer(false);
		const response = await sendRequest({
			method: 'POST',
			url: `/basket/checkout`,
			data: {
				pharmacyId: "1",
				deliveryAdressId: "1",
				paymentMethodId: 1,
				deliveryTime: 1,
				products: [{}],
				tip: 1.2,
				totalPrice: 18
			}
		})
		// wait for drawer animation animation to finish
		setTimeout(() => {
			setShowingCheckoutModal(false);
		}, 500);
	};

	const onCloseSelect = () => {
		setShowingDrawer(false);

		// wait for drawer animation animation to finish
		setTimeout(() => {
			setShowingSelect(false);
		}, 500);
	};

	const [selectedCardId, setSelectedCardId] = useState(1);

	const handleCardSelection = (id) => {
		setSelectedCardId(id);
		console.log(id);
	};

	return (
		<div className='h-full relative'>
			{selectingLocation ? (
				<AddLocation
					center={center}
					zoom={zoom}
					close={closeMapAddressSelect}
				></AddLocation>
			) : (
				<>
					{/* {showingSelect && (
						<BringzzBottomDrawer
							isOpen={showingDrawer}
							close={onCloseSelect}
							title='Select...'
						>
							<div className='flex items-center relative gap-x-4 justify-center'>
								<div className='h-12 absolute top-1/2 right-0 w-full border-y -translate-y-1/2'></div>
								<div>
									<BringzzRotatingSelect
										options={options}
										onSelect={handleChange}
										onClose={onCloseSelect}
									/>
								</div>
							</div>

							<div className='flex justify-center items-center mt-4'>
								<BringzzButton
									className='bg-magic-lilac'
									onClick={onCloseSelect}
								>
									Apply
								</BringzzButton>
							</div>
						</BringzzBottomDrawer>
					)}
					{showingAddressSelect && (
						<BringzzBottomDrawer
							isOpen={showingDrawer}
							close={closeAddressSelection}
							title='Select adress to continue'
						>
							<CheckoutAddressSelect
								close={closeAddressSelection}
								customAddress={customAddress}
								setShowingMapSelect={setSelectingLocation}
							></CheckoutAddressSelect>
						</BringzzBottomDrawer>
					)}*/}

					{showingCheckoutModal && (
						<BringzzBottomDrawer
							isOpen={showingDrawer}
							close={closeCheckoutModal}
							title='Checkout...'
						>
							<CheckoutSummary deliveryType={selectedCardId} close={closeCheckoutModal} />
						</BringzzBottomDrawer>
					)}

					<BringzzTopBar
						sections={[
							{ name: 'location', alignment: 'start', value: 'Wien' },
							{ name: 'title', alignment: 'center', value: 'YOUR BASKET' },
							{
								name: 'notification',
								alignment: 'end',
								value: true,
								onClick: () => navigate('/notifications')
							}
						]}
					/>

					<BringzzPromoBanner
						header='Free delivery – today only!'
						subHeader={
							<>
								Lectus est mi augue amet fames ultrices.{' '}
								<span className='underline'>Promo Terms</span>
							</>
						}
					></BringzzPromoBanner>

					<div className='p-4'>
						<BringzzText tag='h1' className='font-medium'>
							YOUR BASKET
						</BringzzText>
					</div>
					<div className='px-4'>
						{products &&
							products.length > 0 &&
							products.map((product, index) => {
								return (
									<BringzzBasketProduct
										key={index + 'product'}
										product={product}
										mode={'select'}
										showSelect={e => {
											setSelectedIndex(index);
											e.preventDefault();
											setShowingDrawer(true);
											setShowingSelect(true);
										}}
									/>
								);
							})}
					</div>

					<div className='px-4'>
						<div className='py-4 border-b'>
							<BringzzButton
								className='border border-black'
								icon={{ variant: 'IconPlus', folder: 'LineIcons', size: '10' }}
							>
								<span
									className='text-sm'
									onClick={() => {
										const productsCopy = [...products];
										productsCopy.push({
											name: `Product Name ${products.length + 1}`,
											manufacturer: `Manufacturer ${products.length + 1}`,
											amount: '24 Stk | Kapseln',
											quantity: 1,
											imgUrl: '/product.png',
											price: 10
										});
										setProducts(productsCopy);
									}}
								>
									add Item
								</span>
							</BringzzButton>
						</div>
					</div>

					<div className='p-4'>
						<BringzzAccordion
							defaultValue={false}
							title={
								<BringzzText tag='h4' className='font-bold'>
									Promo Code
								</BringzzText>
							}
						>
							<BringzzButton className='bg-sand'>Promo10</BringzzButton>
						</BringzzAccordion>
					</div>

					<div className='px-4'>
						<div className='py-4 border-b'>
							<div className='justify-between flex mb-2'>
								<BringzzText tag='h4' className='font-regular'>
									Subtotal
								</BringzzText>
								<BringzzText tag='h4' className='font-regular'>
									€ {total.toFixed(2)}
								</BringzzText>
							</div>
							<div className='justify-between flex mb-2'>
								<BringzzText tag='h4' className='font-regular'>
									Delivery fee & handling
								</BringzzText>
								<BringzzText tag='h4' className='font-regular'>
									€ 2
								</BringzzText>
							</div>
							<div className='justify-between flex mb-2'>
								<BringzzText tag='h4' className='font-bold'>
									Est. Total
								</BringzzText>
								<BringzzText tag='h4' className='font-bold'>
									€ {(total + 2).toFixed(2)}
								</BringzzText>
							</div>
						</div>
						<div></div>
					</div>

					<BasketRecommendationSection />

					<div className='sticky bottom-24 md:bottom-0 p-4 w-full shadow-top z-20 bg-white'>
						<BringzzCheckoutPicker onCardSelect={handleCardSelection} />

						<div className='w-full'>
							<BringzzButton
								className='bg-magic-lilac rounded-full flex justify-center font-semibold w-full'
								onClick={openCheckoutModal}
								disabled={selectedCardId == 3}
							>
								Checkout
							</BringzzButton>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default BasketPage;
