import React, { useState, useEffect } from 'react';
import { BringzzAccordion, BringzzButton, BringzzPromoBanner, BringzzText } from "@bringzz/components";
import { useParams } from 'react-router-dom';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzBasketProduct,
    BringzzProgressBar,
    BringzzBottomDrawer,
    BringzzRadioList,
    BringzzRadio,
    BringzzInput
} from "@bringzz/components";
import BikeImage from 'assets/images/Bike.png'
import RocketImage from 'assets/images/Rocket.png'

let state = {
    checked: (
        <BringzzIcon
            icon="IconCheck"
            library="Custom"
            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
            size="12"
        />
    ),
    unChecked: (
        <div></div>
    ),
};

const MatchmakingCollectPage = () => {
    const { backPress, navigate } = useNavigation();
    const { step } = useParams();
    const [activeStep, setActiveStep] = useState(step ? parseInt(step, 10) : 0);
    const [selectedTip, setSelectedTip] = useState(null);
    const [cancelOrderDrawer, setCancelOrderDrawer] = useState(false)
    const [selectedReason, setSelectedReason] = useState()

    const handleCancelOrderDrawer = () => {
        setCancelOrderDrawer(!cancelOrderDrawer)
    }

    // To be later replaced with logic
    useEffect(() => {
        const timer = setInterval(() => {
            setActiveStep((prev) => {
                if (prev !== 3) {
                    return prev + 1;
                } else {
                    clearInterval(timer)
                    return prev
                }
            });
        }, 5000);

        return () => clearInterval(timer);
    }, [activeStep]);

    const [products, setProducts] = useState([
        {
            name: 'Product Name 1',
            manufacturer: 'Manufacturer',
            amount: '84 Stk | Kapseln',
            quantity: '10',
            imgUrl: '/product.png',
            price: 9,
            inhalt: ""
        },
        {
            name: 'Product Name 2',
            manufacturer: 'Manufacturer 2',
            amount: '12 Stk | Kapseln',
            quantity: '3',
            imgUrl: '/product.png',
            price: 8,
            inhalt: "Prescription attached"
        }
    ]);

    const segments = [
        {
            status: "Matcing pharmacy...",
            subStatus: 'Pickup within XX:XX',
            content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
                <div className='flex justify-center'>
                    <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
                </div>
                <BringzzText tag='h4' className={"font-normal"}>Waiting for pharmacy to accept request...</BringzzText>
            </div>
        },
        {
            status: "Order is being prepared...",
            subStatus: 'Pickup within XX:XX',
            content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
                <div className='flex justify-center'>
                    <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
                </div>
                <BringzzText tag='h4' className={"font-normal"}>Pharmacy matched!</BringzzText>
                <BringzzText tag='h5' className={"font-normal"}>Your order is now being prepared...</BringzzText>
            </div>
        },
        {
            status: "Ready for collection!",
            subStatus: 'Pickup within XX:XX',
            content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
                <div className='flex justify-center'>
                    <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
                </div>
                <BringzzText tag='h4' className={"font-normal"}>Your order is ready for pickup!</BringzzText>
                <BringzzText tag='h5' className={"font-normal"}>Scan the QR-Code on your order bag to confirm that you have received your order.</BringzzText>
                <BringzzButton onClick={() => navigate('/camera/collect/collect')} className='bg-magic-lilac mt-5'>Confirm collection</BringzzButton>
            </div>
        },
    ]

    const selectTip = amount => {
        setSelectedTip(amount); // Update the selected tip
    };

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                title: "Order #1234",
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconClose'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                right: (
                    <BringzzButton size='sm' className='border border-black'>Help</BringzzButton>
                )
            }}
            authenticated={true}
        >

            <div className='px-6'>
                <div className='py-6'>
                    <BringzzText tag='h1' className='text-center'>{segments[activeStep - 1].status}</BringzzText>
                    <BringzzText tag='h3' className='text-center font-normal'>{segments[activeStep - 1].subStatus}</BringzzText>
                </div>

                <BringzzProgressBar state={activeStep} mode={"custom"} segments={segments} />

                {activeStep > 3 && <div className='flex items-center justify-between pb-4 px-8 cursor-pointer'>
                    <BringzzText tag='h4'>Add Tip</BringzzText>
                    <div className='flex items-center gap-x-3'>
                        <BringzzButton
                            className={`text-olive-green rounded-lg !px-3 flex justify-center font-semibold ${selectedTip === 1 ? 'bg-magic-lilac' : 'bg-sand '}`}
                            onClick={() => selectTip(1)}
                        >
                            €1
                        </BringzzButton>
                        <BringzzButton
                            className={`text-olive-green rounded-lg !px-3 flex justify-center font-semibold ${selectedTip === 3 ? 'bg-magic-lilac ' : 'bg-sand '}`}
                            onClick={() => selectTip(3)}
                        >
                            €3
                        </BringzzButton>
                        <BringzzButton
                            className={`text-olive-green rounded-lg !px-3 flex justify-center font-semibold ${selectedTip === 5 ? 'bg-magic-lilac' : 'bg-sand '}`}
                            onClick={() => selectTip(5)}
                        >
                            €5
                        </BringzzButton>
                        <BringzzButton
                            className={`text-olive-green rounded-lg !px-3 flex justify-center font-semibold ${selectedTip === 'Other' ? 'bg-magic-lilac' : 'bg-sand '}`}
                            onClick={e => {
                                e.stopPropagation();
                                selectTip('Other');
                            }}
                        >
                            Other
                        </BringzzButton>
                    </div>
                </div>}

                <BringzzAccordion
                    title={<div className='flex space-x-4'>
                        <div>
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconCheckBoxAlt'
                                size='22'
                                className='p-3 rounded-full' />
                        </div>
                        <div>
                            <BringzzText tag='h3'>Order</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Total: €18,00</BringzzText></div>
                    </div>}
                    className='border-t'
                >
                    {products.map((product, index) => {
                        return (
                            <BringzzBasketProduct
                                key={index + 'product'}
                                product={product}
                                mode={"view"}
                            />
                        );
                    })}
                    <div className='py-3'>
                        <div className='flex justify-between'>
                            <BringzzText tag='h4' className="font-normal">Subtotal</BringzzText>
                            <BringzzText tag='h4' className="font-normal">€XX.XX</BringzzText>
                        </div>
                        <div className='flex justify-between'>
                            <BringzzText tag='h4' className="font-normal">Delivery fee & handling</BringzzText>
                            <BringzzText tag='h4' className="font-normal">€XX.XX</BringzzText>
                        </div>
                        <div className='flex justify-between'>
                            <div className='flex space-x-1 items-center'>
                                <BringzzText tag='h4'>Total</BringzzText>
                                <BringzzIcon
                                    icon="IconQuestionCircle"
                                    folder="LineIcons"
                                    className="h-4 w-4 cursor-pointer text-magic-lilac"
                                    size="16"
                                ></BringzzIcon>
                            </div>
                            <BringzzText tag='h4'>€XX.XX</BringzzText>
                        </div>
                    </div>
                </BringzzAccordion>
                <div className='flex space-x-4 border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconTicketAlt'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div className='flex w-full justify-between'>
                        <div>
                            <BringzzText tag='h3'>Invoice</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Invoice #2023-123456</BringzzText>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconEye'
                                size='22' />
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconUpload'
                                className={activeStep < 7 ? 'text-black/30' : ''}
                                size='16' />
                        </div>
                    </div>
                </div>

                {activeStep < 2 ?
                    <div className='flex space-x-4 border-b py-1 text-black/20'>
                        <div>
                            <BringzzIcon
                                folder='HealthIcons'
                                icon='IconPharmacy'
                                size='22'
                                className='p-3 rounded-full' />
                        </div>
                        <div className='flex w-full items-center'>
                            <BringzzText tag='h3' className='text-black/20'>Pharmacy</BringzzText>
                        </div>
                    </div>
                    :
                    <div className='flex flex-col space-y-1 space-x-4 border-b py-2'>
                        <div className='flex'>
                            <div>
                                <BringzzIcon
                                    folder='HealthIcons'
                                    icon='IconPharmacy'
                                    size='22'
                                    className='p-3 rounded-full' />
                            </div>
                            <div className='flex w-full pl-4 justify-between'>
                                <div>
                                    <BringzzText tag='h3'>Pharmacy</BringzzText>
                                    <BringzzText tag='h4' className={"font-light"}>Apotheke Mustermann</BringzzText>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <BringzzIcon
                                        folder='LineIcons'
                                        icon='IconInfoCircle'
                                        size='22' />
                                    <BringzzIcon
                                        folder='LineIcons'
                                        icon='IconPhone'
                                        size='16' />
                                    <BringzzIcon
                                        folder='LineIcons'
                                        icon='IconSupport'
                                        size='21' />
                                </div>
                            </div>
                        </div>
                        <BringzzButton size='sm' className='border border-magic-lilac text-magic-lilac'>Get directions</BringzzButton>
                    </div>}

                <BringzzButton size='md' className='border border-red-600 text-red-600 mt-4' onClick={handleCancelOrderDrawer}>Cancel order</BringzzButton>
            </div>

            <BringzzBottomDrawer
                title='Cancel order'
                isOpen={cancelOrderDrawer}
                close={handleCancelOrderDrawer}
            >
                <div className='px-6 pb-6 space-y-4'>
                    <div>
                        <BringzzText tag='h4' className='font-normal'>Let us know why you are cancelling your order.</BringzzText>
                        <BringzzText tag='h4' className='font-normal'>Keep in mind cancellation fees can apply.</BringzzText>
                    </div>
                    <div className='space-y-2'>
                        <div className='px-4'>
                            <BringzzRadioList
                                onChange={(newValue) => {
                                    setSelectedReason(newValue)
                                }}
                                state={state}
                                className="rounded-xl w-full text-center space-y-2"

                            >
                                <BringzzRadio value="1"
                                    className="p-2 py-2 hover:bg-slate- border rounded-md border-gray-400 flex"
                                >
                                    <BringzzText tag='h4'>Ordered by mistake</BringzzText>
                                </BringzzRadio>
                                <BringzzRadio value="2"
                                    className="p-2 py-2 hover:bg-slate- border rounded-md border-gray-400 flex"
                                >
                                    <BringzzText tag='h4'>{"Pharmacy/shopper didn't respond"}</BringzzText>
                                </BringzzRadio>
                            </BringzzRadioList>
                            <div className='mt-2'>
                                <BringzzInput placeholder={'Your custom reason'} className='border' onChange={(e, newIsValid, newValue) => {
                                    setSelectedReason(newValue)
                                }}></BringzzInput>
                            </div>
                        </div>
                        <BringzzButton onClick={() => navigate('/matchmaking/collect/cancelled')} size='md' disabled={!selectedReason} className='bg-magic-lilac w-full flex justify-center'>Cancel order</BringzzButton>
                        <BringzzButton onClick={handleCancelOrderDrawer} size='md' className='text-magic-lilac border border-magic-lilac w-full flex justify-center'>Cancel</BringzzButton>
                    </div>
                </div>
            </BringzzBottomDrawer>
        </BringzzPageContainer >
    );
};

export default MatchmakingCollectPage;
