import React, { useState } from 'react';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzText,
    BringzzImage,
    BringzzPostingCard,
    BringzzButton, BringzzBottomDrawer,
    BringzzRotatingSelect,
    BringzzArticle,
    BringzzInput
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import AvatarExample from 'assets/images/AvatarExample.png';
import usePageState from 'hooks/usePageState';
import { useAuth } from 'context/AuthContext';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PreviewArticlePage = () => {
    const { backPress } = useNavigation();
    let query = useQuery();
    const { user } = useAuth();
    const { navigate } = useNavigation();
    const [currentHours, setCurrentHours] = useState(null);
    const [currentMinutes, setCurrentMinutes] = useState(null);
    const [selectingTime, setSelectingTime] = useState(false);
    const [scheduledTime, setScheduledTime] = useState(null);

    const formData = usePageState();

    const hoursOptions = [
        { label: '00', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
        { label: '10', value: 10 },
        { label: '11', value: 11 },
        { label: '12', value: 12 },
        { label: '13', value: 13 },
        { label: '14', value: 14 },
        { label: '15', value: 15 },
        { label: '16', value: 16 },
        { label: '17', value: 17 },
        { label: '18', value: 18 },
        { label: '19', value: 19 },
        { label: '20', value: 20 },
        { label: '21', value: 21 },
        { label: '22', value: 22 },
        { label: '23', value: 23 },
        { label: '24', value: 24 }
    ];

    const changeHours = result => {
        setCurrentHours(result.value);
    };
    const changeMinutes = result => {
        setCurrentMinutes(result.value);
    };
    const closeTimeSelection = () => {
        const time = `${currentHours}:${currentMinutes.toString().padStart(2, '0')}`;
        setScheduledTime(time);
        setSelectingTime(false);
    };
    const generateMinutesOptions = () => {
        let array = [];
        for (let index = 0; index < 60; index++) {
            array.push({ value: index, label: index.toString().padStart(2, '0') });
        }
        return array;
    };
    const minutesOptions = generateMinutesOptions();

    return (
        <BringzzPageContainer
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),

                title: <BringzzText tag='h2'>Preview Article</BringzzText>
            }}
            footer={
                <div className='pb-28 px-6 flex flex-row space-x-2'>
                    <BringzzButton
                        onClick={() => {
                            navigate(`/posts/${scheduledTime ? 'scheduled' : 'published'}/article`)
                        }}
                        size='md'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'

                    >
                        Share {scheduledTime ? `| ${scheduledTime}` : ''}
                    </BringzzButton>
                    <div className='flex space-x-1'>
                        <BringzzIcon
                            folder='LineIcons'
                            className='bg-magic-lilac text-midnight-blue p-2 rounded-full'
                            size='20'
                            icon='IconClockAlt1'
                            onClick={() => setSelectingTime(true)}
                        />
                    </div>
                </div>
            }
        >
            <div className=''>
                <BringzzArticle
                    title={formData.headline || ''}
                    subTitle={formData.leadIn || ''}
                    likesCount={100}
                    commentsCount={10}
                    interactionBar={false}
                />

                <div className="flex items-center space-x-2 px-4">
                    <img src={AvatarExample} className="h-8 w-8" alt="avatar" />
                    <BringzzText
                        tag="h4"
                        className={"font-bold"}
                    >
                        {user.fullName}
                    </BringzzText>
                    <div className='flex items-center space-x-1'>
                        <span className="text-[5px]">&#9679;</span>
                        <BringzzText
                            tag="h4"
                            className={'font-normal text-magic-lilac'}
                        >
                            Follow
                        </BringzzText>
                    </div>
                </div>

                <div className='mt-3'>
                    <BringzzImage src={formData.photo} alt='article image' className='h-56 w-full' />
                    <BringzzText className={'font-normal italic p-3'} tag='h5'>Image Caption</BringzzText>
                </div>
                {formData.blocks && formData.blocks.map((block, index) => {
                    return <div key={index} className='px-3'>
                        {block.type == "body_copy" && <BringzzText tag='h4'>{block.content}</BringzzText>}
                        {block.type == "inline_heading" && <BringzzText tag='h2'>{block.content}</BringzzText>}
                    </div>
                })}
            </div>
            <BringzzBottomDrawer
                isOpen={selectingTime}
                close={closeTimeSelection}
                title='Schedule Post'
            >
                <div className='flex items-center relative gap-x-4 justify-center'>
                    <div className='h-12 absolute top-1/2 right-0 w-full border-y -translate-y-1/2'></div>
                    <div>
                        <BringzzRotatingSelect
                            options={hoursOptions}
                            onSelect={changeHours}
                        />
                    </div>
                    <div>
                        <BringzzRotatingSelect
                            options={minutesOptions}
                            onSelect={changeMinutes}
                        />
                    </div>
                </div>

                <div className='flex justify-center items-center py-6'>
                    <BringzzButton
                        size='lg'

                        className='bg-magic-lilac '
                        onClick={closeTimeSelection}
                    >
                        Apply
                    </BringzzButton>
                </div>
            </BringzzBottomDrawer>
        </BringzzPageContainer>

    );
};

export default PreviewArticlePage;
