import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import io from 'socket.io-client';
import useUser from 'hooks/useUser';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const userId = -1

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
            query: { userId }
        });
        setSocket(newSocket);

        return () => newSocket.close();
    }, [userId]);

    const sendMessage = (message) => {
        if (socket) socket.emit('sendMessage', message);
    };

    const onRefetchData = useCallback((callback) => {
        if (socket) {
            socket.on('refetchData', callback);
            return () => socket.off('refetchData', callback);
        }
    }, [socket]);

    const value = {
        socket,
        sendMessage,
        onRefetchData,
    };

    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    );
};