import { Routes, Route, Navigate } from 'react-router-dom';
import { useHandleBackButton } from './hooks/useHandleBackButton';
import useResponsive from './hooks/useResponsive';

import MobileLayout from './layout/MobileLayout';
import DesktopLayout from './layout/DesktopLayout';

import HomePage from './pages/Home/HomePage';
import BasketPage from './pages/Basket/BasketPage';
import ProfilePage from './pages/Profile/ProfilePage';
import SettingsPage from './pages/Settings/SettingsPage';
import SplashPage from './pages/Splash/SplashPage';
import OnboardingPage from './pages/Onboarding';
import AuthPage from './pages/Auth';
import LoginPage from './pages/Auth/Login';
import ScanPage from './pages/Scan/ScanPage';
import SearchOverviewPage from './pages/Search/SearchOverviewPage';
import NotificationsPage from 'pages/Notifications';
import TermsPage from 'pages/Terms/TermsPage';

/* Demos */
import ProfilePharmacy2 from './demos/ProfilePharmacy2';
import ProfilePharmacyViews5 from './demos/ProfilePharmacyViews5';
import MatchingProcessChangeShopper2 from './demos/MatchingProcessChangeShopper2';
import AccountPharmacyChat5 from './demos/AccountPharmacyChat5';
import OrderRequest6 from './demos/OrderRequest6';
import OrderRequest2 from './demos/OrderRequest2';
import MapZipcodesDemo from './demos/MapZipcodesDemo';
import AccountConsumerChat9 from './demos/AccountConsumerChat9';
import MatchingProcessShopper3 from './demos/MatchingProcessShopper3';
import PharmacyPostProduct1 from './demos/PharmacyPostProduct1';
import PharmacyPostArticle3 from './demos/PharmacyPostArticle3';
import DeliveryHandoverShipping9 from './demos/DeliveryHandoverShipping9';
import DeliveryHandoverViewPrescription3 from './demos/DeliveryHandoverViewPrescription3';
import AccountPharmacyOrder1 from './demos/AccountPharmacyOrder1';
import AccountPharmacyPost10 from './demos/AccountPharmacyPost10';
import Article from 'demos/Article';
import OTPpage from 'pages/Auth/OTP';
import ResetPasswordPage from 'pages/Auth/ResetPassword';
import ResetPasswordSuccessPage from "pages/Auth/ResetPasswordSuccessPage";
import AppSettingsPage from 'pages/Settings/App/AppSettingsPage';
import FaqPage from 'pages/Faq';
import FaqGeneralPage from 'pages/Faq/General';
import ConsumerProfilePage from 'pages/Auth/Consumer/Profile';
import ConsumerInsurancePage from 'pages/Auth/Consumer/Insurance';
import ConsumerPaymentPage from 'pages/Auth/Consumer/Payment';
import ConsumerCardPage from 'pages/Auth/Consumer/Card';
import ConsumerLocationPage from 'pages/Auth/Consumer/Location';
import AllowNotificationPage from 'pages/Auth/Notification';
import AllowTrackingPage from 'pages/Auth/Tracking';
import AllowFaceIDPage from 'pages/Auth/FaceID';
import ConsumerInterestPage from 'pages/Auth/Consumer/Interest';
import ConsumerBrandsPage from 'pages/Auth/Consumer/Brands';
import ShopperProfilePage from 'pages/Auth/Shopper/Profile';
import AllowVerificationPage from 'pages/Auth/Shopper/Verification';
import ShopperBankPage from 'pages/Auth/Shopper/Bank';
import ShopperAddressPage from 'pages/Auth/Shopper/Address';
import ShopperCompletePage from 'pages/Auth/Shopper/Complete';
import RegisterPage from 'pages/Auth/Register';
import RoleSwitcherPage from 'pages/Auth/RoleSwitcher';
import RegisterShopperPage from 'pages/Auth/RegisterShopper';
import PharmacyProfilePage from 'pages/Auth/Pharmacy/Profile';
import RegisterPharmacyPage from 'pages/Auth/RegisterPharmacy';
import PharmacyOwnerPage from 'pages/Auth/Pharmacy/Owner';
import NightDutyPage from 'pages/Auth/Pharmacy/NightDuty';
import BankDetailsPage from 'pages/Auth/Pharmacy/BankDetails';
import InventoryControlPage from 'pages/Auth/Pharmacy/InventoryControl';
import PharmacyOnboardingComplete from 'pages/Auth/Pharmacy/Complete';
import InventoryPermissionPage from 'pages/Auth/Pharmacy/InventoryPermission';
import PharmacyCardPage from 'pages/Auth/Pharmacy/Card';
import OpeningHoursPage from 'pages/Auth/Pharmacy/OpeningHours';
import AddressPage from 'pages/Address';
import RegisterOTPpage from 'pages/Auth/Register/OTP';
import ConsumerPicturePage from 'pages/Auth/Consumer/Picture';
import AccountPage from 'pages/Account';
import EditNameView from 'pages/Account/Name';
import EditMailView from 'pages/Account/Email';
import VerifyMailView from 'pages/Account/Email/Verify';
import EditPhoneNumberView from 'pages/Account/PhoneNumber';
import VerifyPhoneNumberView from 'pages/Account/PhoneNumber/Verify';
import EditAddressView from 'pages/Account/Address';
import EditBioView from 'pages/Account/Bio';
import EditIDVerificationView from 'pages/Account/IdVerification';
import EditPrivateInsuranceView from 'pages/Account/PrivateInsurance';
import EditSVNRView from 'pages/Account/Svnr';
import EditDoctorVerificationView from 'pages/Account/DoctorVerification';
import PharmacyView from 'pages/Pharmacy';
import PharmacyInfo from 'pages/Pharmacy/PharmacyInfo';
import EditPharmacyProfile from 'pages/Pharmacy/PharmacyInfo/Profile';
import EditPharmacyAddress from 'pages/Pharmacy/PharmacyInfo/Address';
import EditPharmacyNightDuties from 'pages/Pharmacy/PharmacyInfo/NightDuties';
import PharmacyTeamView from 'pages/Pharmacy/Team';
import EditPharmacyEmployeeInfo from 'pages/Pharmacy/Team/EditEmployee';
import ViewPharmacyInventory from 'pages/Pharmacy/Inventory';
import AddPharmacyEmployee from 'pages/Pharmacy/Team/AddEmployee';
// import EditPharmacyOpeningHours from 'pages/Pharmacy/PharmacyInfo/OpeningHours';

import { LanguageProvider as BringzzLanguageProvider } from "@bringzz/components";
import { useLanguage } from 'context/LanguageContext';
import DeleteAccountPage from 'pages/Settings/App/Delete/DeleteAccountPage';
import VerifyAccountDeletionPage from 'pages/Settings/App/Delete/Verify/VerifyAccountDeletionPage';
import EditPharmacyOpeningHours from 'pages/Pharmacy/PharmacyInfo/OpeningHours';
import BookmarksView from 'pages/Bookmarks';
import BookMarksCollection from 'pages/Bookmarks/Collection';
import ConsumerAddressPage from 'pages/Auth/Consumer/Address';
import AllowWhatsappPage from 'pages/Auth/Whatsapp';
import ConsumerSuccessPage from 'pages/Auth/Consumer/Success';
import OrdersPage from 'pages/Orders';
import CompletedOrdersPage from 'pages/Orders/Completed';
import OrderViewPage from 'pages/Orders/View';
import OrderPlacePage from 'pages/Orders/Place';
import Posts from 'pages/Posts';
import PostDetail from 'pages/Posts/PostDetail/PostDetail';
import SettingsEditPost from 'pages/Posts/EditPost';
import PostPreview from 'pages/Posts/EditPost/PreviewPost';
import PostSuccess from 'pages/Posts/EditPost/Success';
import ShopperAccount from 'pages/Account/Shopper';
import ShopperDeliveries from 'pages/Account/Shopper/AllDeliveries';
import ShopperDeliveryDetail from 'pages/Account/Shopper/DeliveryDetail';
import ShopperDeliveryInvoice from 'pages/Account/Shopper/Invoice';
import ConsumerOrdersPage from 'pages/Consumer/Orders';
import ConsumerOrderViewPage from 'pages/Consumer/Orders/View';
import SearchPage from 'pages/Search';
import SearchCategoryPage from 'pages/Search/Category';
import SearchFilterPage from 'pages/Search/Filter';
import Testing from 'pages/Testing';
import SearchPharmacyPage from 'pages/Search/pharmacy';
import ChatsPage from 'pages/Chat';
import NewChatPage from 'pages/Chat/New';
import ConversationPage from 'pages/Chat/Conversation';
import CmsPage from 'pages/CmsPage';
import CameraPage from 'pages/Camera';
import MatchmakingPickupPage from 'pages/Matchmaking/pickup';
import MatchmakingPickupFinishedPage from 'pages/Matchmaking/pickup/completed';
import MatchmakingCollectPage from 'pages/Matchmaking/collect';
import MatchmakingCollectFinishedPage from 'pages/Matchmaking/collect/completed';
import ShopperOverviewPage from 'pages/Shopper/Overview';
import MatchmakingPickupCancelledPage from 'pages/Matchmaking/pickup/cancelled';
import MatchmakingCollectCancelledPage from 'pages/Matchmaking/collect/cancelled';
import AddPostPage from 'pages/Posts/AddPost';
import AddExternalLinkPage from 'pages/Posts/AddExternalLink';
import PreviewExternalLink from 'pages/Posts/PreviewExternalLink';
import ResultExternalLink from 'pages/Posts/ResultExternalLink';
import AddArticlePage from 'pages/Articles/AddArticle';
import BuildArticlePage from 'pages/Articles/BuildArticle';
import PreviewArticlePage from 'pages/Articles/PreviewArticle';
import ResultArticlePage from 'pages/Articles/ResultArticle';
import PreviewProductPost from 'pages/Posts/PreviewProductPost';
import ResultProductPost from 'pages/Posts/ResultProductPost';
import DemosPage from 'pages/Demos';

function App() {
	const isMobile = useResponsive('(max-width: 767px)');
	const { language } = useLanguage()

	useHandleBackButton();

	return (
		<BringzzLanguageProvider language={language}>
			<div className='h-full w-full'>
				<Routes>
					<Route path='/' element={<Navigate replace to='/splash' />} />
					<Route path='/scan' element={<ScanPage />} />

					<Route element={isMobile ? <MobileLayout /> : <DesktopLayout />}>
						<Route path='/testing' element={<Testing />} />
						<Route path='/home' element={<HomePage />} />
						<Route path='/basket' element={<BasketPage />} />
						<Route path='/profile' element={<SettingsPage />} />
						<Route path='/demos' element={<DemosPage />} />
						<Route path='/search' element={<SearchOverviewPage />} />
						<Route path='/search/*' element={<SearchPage />} />
						<Route path='/search/category/*' element={<SearchCategoryPage />} />
						<Route path='/search/filter/' element={<SearchFilterPage />} />
						<Route path='/search/pharmacy/' element={<SearchPharmacyPage />} />

						<Route path='/notifications' element={<NotificationsPage />} />
						<Route path='/settings' element={<SettingsPage />} />
						<Route path='/settings/app' element={<AppSettingsPage />} />
						<Route path='/settings/app/delete' element={<DeleteAccountPage />} />
						<Route path='/settings/app/delete/verify' element={<VerifyAccountDeletionPage />} />
						<Route path='/terms' element={<TermsPage />} />
						<Route path='/faq' element={<FaqPage />} />
						<Route path='/faq/general' element={<FaqGeneralPage />} />
						<Route path='/settings/account' element={<AccountPage />} />
						<Route path='/settings/account/name' element={<EditNameView />} />
						<Route path='/settings/account/bio' element={<EditBioView />} />
						<Route path='/settings/account/email' element={<EditMailView />} />
						<Route path='/settings/account/email/verify' element={<VerifyMailView />} />
						<Route path='/settings/account/mobile' element={<EditPhoneNumberView />} />
						<Route path='/settings/account/id' element={<EditIDVerificationView />} />
						<Route path='/settings/account/mobile/verify' element={<VerifyPhoneNumberView />} />
						<Route path='/settings/account/address' element={<EditAddressView />} />
						<Route path='/settings/account/insurance' element={<EditPrivateInsuranceView />} />
						<Route path='/settings/account/svnr' element={<EditSVNRView />} />
						<Route path='/settings/account/doctor' element={<EditDoctorVerificationView />} />
						<Route path='/settings/pharmacy' element={<PharmacyView />} />
						<Route path='/settings/pharmacy/info' element={<PharmacyInfo />} />
						<Route path='/settings/pharmacy/info/profile' element={<EditPharmacyProfile />} />
						<Route path='/settings/pharmacy/info/address' element={<EditPharmacyAddress />} />
						<Route path='/settings/pharmacy/info/duties' element={<EditPharmacyNightDuties />} />
						<Route path='/settings/pharmacy/info/opening' element={<EditPharmacyOpeningHours />} />
						<Route path='/settings/pharmacy/team' element={<PharmacyTeamView />} />
						<Route path='/settings/pharmacy/team/edit' element={<EditPharmacyEmployeeInfo />} />
						<Route path='/settings/pharmacy/team/add' element={<AddPharmacyEmployee />} />
						<Route path='/settings/pharmacy/inventory' element={<ViewPharmacyInventory />} />
						<Route path='/settings/bookmarks' element={<BookmarksView />} />
						<Route path='/settings/bookmarks/collection' element={<BookMarksCollection />} />
						<Route path='/settings/pharmacy/orders' element={<OrdersPage />} />
						<Route path='/settings/pharmacy/orders/completed' element={<CompletedOrdersPage />} />
						<Route path='/settings/pharmacy/orders/view' element={<OrderViewPage />} />
						<Route path='/settings/pharmacy/orders/place' element={<OrderPlacePage />} />
						<Route path='/settings/posts' element={<Posts />} />
						<Route path='/settings/posts/detail' element={<PostDetail />} />
						<Route path='/settings/posts/edit' element={<SettingsEditPost />} />
						<Route path='/settings/posts/preview' element={<PostPreview />} />
						<Route path='/settings/posts/success' element={<PostSuccess />} />
						<Route path='/settings/account/shopper' element={<ShopperAccount />} />
						<Route path='/settings/account/shopper/deliveries' element={<ShopperDeliveries />} />
						<Route path='/settings/account/shopper/deliveries/detail' element={<ShopperDeliveryDetail />} />
						<Route path='/settings/account/shopper/deliveries/invoice' element={<ShopperDeliveryInvoice />} />
						<Route path='/consumer/orders' element={<ConsumerOrdersPage />} />
						<Route path='/consumer/orders/view' element={<ConsumerOrderViewPage />} />

						<Route path='/chats/:id' element={<ConversationPage />} />
						<Route path='/chats' element={<ChatsPage />} />
						<Route path='/chats/new' element={<NewChatPage />} />

						<Route path='/shopper/:id/overview' element={<ShopperOverviewPage />} />

						<Route path='/posts/add/product-post' element={<AddPostPage />} />
						<Route path='/posts/preview/product-post' element={<PreviewProductPost />} />
						<Route path='/posts/:result/product-post' element={<ResultProductPost />} />

						<Route path='/posts/add/external-link' element={<AddExternalLinkPage />} />
						<Route path='/posts/preview/external-link' element={<PreviewExternalLink />} />
						<Route path='/posts/:result/external-link' element={<ResultExternalLink />} />

						<Route path='/posts/add/article' element={<AddArticlePage />} />
						<Route path='/posts/build/article' element={<BuildArticlePage />} />
						<Route path='/posts/preview/article' element={<PreviewArticlePage />} />
						<Route path='/posts/:result/article' element={<ResultArticlePage />} />

						{/* Demos */}
						<Route path='/demos/ProfilePharmacy2' element={<ProfilePharmacy2 />} />
						<Route path='/demos/ProfilePharmacyViews5' element={<ProfilePharmacyViews5 />} />
						<Route path='/demos/MatchingProcessChangeShopper2' element={<MatchingProcessChangeShopper2 />} />
						<Route path='/demos/AccountPharmacyChat5' element={<AccountPharmacyChat5 />} />
						<Route path='/demos/OrderRequest6' element={<OrderRequest6 />} />
						<Route path='/demos/OrderRequest2' element={<OrderRequest2 />} />
						<Route path='/demos/MapZipcodesDemo' element={<MapZipcodesDemo />} />
						<Route path='/demos/AccountConsumerChat9' element={<AccountConsumerChat9 />} />
						<Route path='/demos/MatchingProcessShopper3' element={<MatchingProcessShopper3 />} />
						<Route path='/demos/Article' element={<Article />} />
						<Route path='/demos/PharmacyPostProduct1' element={<PharmacyPostProduct1 />} />
						<Route path='/demos/PharmacyPostArticle3' element={<PharmacyPostArticle3 />} />
						<Route path='/demos/AccountPharmacyOrder1' element={<AccountPharmacyOrder1 />} />
						<Route path='/demos/DeliveryHandoverShipping9' element={<DeliveryHandoverShipping9 />} />
						<Route path='/demos/DeliveryHandoverViewPrescription3' element={<DeliveryHandoverViewPrescription3 />} />
						<Route path='/demos/AccountPharmacyPost10' element={<AccountPharmacyPost10 />} />
					</Route>

					<Route path='/splash' element={<SplashPage />} />
					<Route path='/onboarding' element={<OnboardingPage />} />
					<Route path='/auth' element={<AuthPage />} />
					<Route path='/login' element={<LoginPage />} />
					<Route path='/register' element={<RegisterPage />} />
					<Route path='/register/shopper/' element={<RegisterShopperPage />} />
					<Route path='/login/otp' element={<OTPpage />} />
					<Route path='/register/otp' element={<RegisterOTPpage />} />
					<Route path='/reset-password' element={<ResetPasswordPage />} />
					<Route path='/reset-password/success' element={<ResetPasswordSuccessPage />} />

					<Route path='/register/role-switcher' element={<RoleSwitcherPage />} />
					<Route path='/register/consumer/picture' element={<ConsumerPicturePage />} />
					<Route path='/register/consumer/profile' element={<ConsumerProfilePage />} />
					<Route path='/register/consumer/insurance' element={<ConsumerInsurancePage />} />
					<Route path='/register/consumer/payment' element={<ConsumerPaymentPage />} />
					<Route path='/register/consumer/card' element={<ConsumerCardPage />} />
					<Route path='/register/consumer/location' element={<ConsumerLocationPage />} />
					<Route path='/register/consumer/address' element={<ConsumerAddressPage />} />
					<Route path='/register/consumer/interest' element={<ConsumerInterestPage />} />
					<Route path='/register/consumer/brands' element={<ConsumerBrandsPage />} />

					<Route path='/register/shopper/profile' element={<ShopperProfilePage />} />
					<Route path='/register/shopper/verification' element={<AllowVerificationPage />} />
					<Route path='/register/shopper/bank' element={<ShopperBankPage />} />
					<Route path='/register/shopper/address' element={<ShopperAddressPage />} />
					<Route path='/register/shopper/complete' element={<ShopperCompletePage />} />
					<Route path='/register/pharmacy/' element={<RegisterPharmacyPage />} />

					<Route path='/register/pharmacy/profile' element={<PharmacyProfilePage />} />
					<Route path='/register/pharmacy/owner' element={<PharmacyOwnerPage />} />
					<Route path='/register/pharmacy/night-duty' element={<NightDutyPage />} />
					<Route path='/register/pharmacy/bank-details' element={<BankDetailsPage />} />
					<Route path='/register/pharmacy/inventory-permission/' element={<InventoryPermissionPage />} />
					<Route path='/register/pharmacy/card/' element={<PharmacyCardPage />} />
					<Route path='/register/pharmacy/inventory/' element={<InventoryControlPage />} />
					<Route path='/register/pharmacy/complete/' element={<PharmacyOnboardingComplete />} />
					<Route path='/register/tracking' element={<AllowTrackingPage />} />
					<Route path='/register/notification' element={<AllowNotificationPage />} />
					<Route path='/register/whatsapp' element={<AllowWhatsappPage />} />
					<Route path='/register/consumer/success' element={<ConsumerSuccessPage />} />
					<Route path='/register/faceid' element={<AllowFaceIDPage />} />
					<Route path='/register/pharmacy/opening-hours/' element={<OpeningHoursPage />} />
					<Route path='/address/' element={<AddressPage />} />
					<Route path='/matchmaking/pickup/:step' element={<MatchmakingPickupPage />} />
					<Route path='/matchmaking/pickup/finished' element={<MatchmakingPickupFinishedPage />} />
					<Route path='/matchmaking/pickup/cancelled' element={<MatchmakingPickupCancelledPage />} />
					<Route path='/matchmaking/collect/:step' element={<MatchmakingCollectPage />} />
					<Route path='/matchmaking/collect/finished' element={<MatchmakingCollectFinishedPage />} />
					<Route path='/matchmaking/collect/cancelled' element={<MatchmakingCollectCancelledPage />} />

					<Route path='/camera/:type/:mode' element={<CameraPage />} />
					{/* Catch-all route */}

					<Route path="*" element={<CmsPage />} />
				</Routes>
			</div>
		</BringzzLanguageProvider>
	);
}

export default App;
