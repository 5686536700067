import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import qs from 'qs';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';
import { useProgressOverlay } from 'context/ProgressOverlayContext';

const AuthContext = createContext();

// Function to enhance user with fullname and initials
const enhanceUser = (user) => {
  if (user) {
    const fullName = `${user.firstName || ''} ${user.lastName || ''}`.trim(); // Handle missing names
    const initials = `${(user.firstName || '').charAt(0)}${(user.lastName || '').charAt(0)}`.toUpperCase(); // Get initials

    return {
      ...user,
      fullName,
      initials,
      getInitials: function () {
        return this.initials;
      }
    };
  }
  return user;
};

export const AuthProvider = ({ children }) => {
  const { hideOverlay } = useProgressOverlay();
  const { getUser, setUser } = useUser();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const { sendRequest } = useRequest();

  const login = async (credentials) => {
    try {
      const data = qs.stringify({
        'app_login': credentials.username,
        'app_password': credentials.password,
        'method': 'GET'
      });

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BACKEND_URL}/apiCall/chat`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data
      };

      const response = await axios.request(config);
      console.log("============= LOGIN =================");
      console.log(response);
      if (response.data.token) {
        setToken(response.data.token);
        localStorage.setItem('token', response.data.token);
        await fetchUser(response.data.token, credentials.id);
        return true;
      } else {
        setUser(null);
        setRoles([]);
        setToken(null);
        localStorage.removeItem('token');
        return false;
      }
    } catch (error) {
      console.error('Error during login:', error);
      setUser(null);
      setRoles([]);
      setToken(null);
      localStorage.removeItem('token');
      return false;
    }
  };

  const loginWithToken = async (token, user) => {
    try {
      // Enhance user with fullname and initials
      setUser(enhanceUser(user));
      setRoles([user.userRole]);
      setToken(token);
      localStorage.setItem('token', token);
      localStorage.setItem('userId', user.id);
      localStorage.setItem('user', JSON.stringify(user));
      return true;
    } catch (error) {
      console.error('Error during login with token:', error);
      setUser(null);
      setRoles([]);
      setToken(null);
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('user');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const loginWithFacebook = () => {
    try {
      if (window.FB) {
        window.FB.login(function (response) {
          if (response.authResponse) {
            const accessToken = response.authResponse.accessToken;
            window.FB.api('/me', 'GET', { fields: 'id,name,email' }, async function (userResponse) {
              if (userResponse && !userResponse.error) {
                const { email } = userResponse;
                const response = await sendRequest({
                  method: 'POST',
                  url: `/module/user/facebookAuthCallback`,
                  data: { email }
                });

                if (response.data) {
                  loginWithToken(response.data.token, response.data);
                } else {
                  alert('must sign up first.');
                }
              } else {
                console.error('Error fetching user data:', userResponse.error);
              }
            });
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        }, { scope: 'public_profile,email' });
      } else {
        console.error('Facebook SDK not loaded.');
      }
    } catch (error) {
      console.error('Error processing Facebook login:', error);
    }
  };

  const logout = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/logout.php`);
      setUser(null);
      setRoles([]);
      setToken(null);
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('user');
      hideOverlay()
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const fetchUser = async (token, id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/module/user/fetchUser`, {
        params: { id },
        // headers: {
        //   'Authorization': `Bearer ${token}`
        // }
      });
      if (response.data.status === 'success') {
        const userData = response.data.data;
        setUser(enhanceUser(userData)); // Enhance user object with fullname and initials
        setRoles([userData.userRole]);
      } else {
        localStorage.removeItem('token');
        setToken(null);
        setUser(null);
        setRoles([]);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setUser(null);
      setRoles([]);
    } finally {
      setLoading(false);
    }
  };

  const hasToken = useCallback(() => {
    return !!localStorage.getItem('token');
  }, []);

  useEffect(() => {
    if (token) {
      const savedUser = getUser();
      console.log("================== USE EFFECT AUTH ==========================");
      console.log(savedUser);
      if (savedUser) {
        setRoles([savedUser.userRole]); // Set roles from saved user data
        setLoading(false);
      } else {
        const id = localStorage.getItem('userId'); // Retrieve stored user ID
        if (id) {
          fetchUser(token, id);
        } else {
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
    }
  }, [token, getUser]);

  return (
    <AuthContext.Provider value={{ user: getUser(), setUser, roles, login, loginWithToken, loginWithFacebook, logout, loading, hasToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
